@font-face { font-family: outfit; 
    src: url('../font/Outfit-Regular.ttf'); 
    font-display: swap;
} 


/* GLOBAL VARIABLES */
:root {
    /* FONTS */
    --font-family-default: "Outfit", Arial, Helvetica, sans-serif;
    --font-family-text: "Outfit", Arial, Helvetica, sans-serif;
    /* --font-family-handwritten: 'Italianno', serif; */

    /* FONT SIZES */
    --font-size-0: 4rem;
    --font-size-1: 3rem;
    --font-size-2: 2.5rem;
    --font-size-3: 2rem;
    --font-size-4: 1.3rem;
    --font-size-5: 1.1rem;
    --font-size-6: 1rem;
    --font-size-7: .85rem;
    --font-size-8: .5rem;

    /* FONT WEIGHTS */
    --font-weight-vbold: 800;
    --font-weight-bold: 700;
    --font-weight-semibold: 600;
    --font-weight-medium: 500;
    --font-weight-semilight: 400;
    --font-weight-light: 300;
    --font-weight-vlight: 200;

    --shadow-text-outline: -1px -1px 0 var(--secondary-color), 1px -1px 0 var(--secondary-color), -1px 1px 0 var(--secondary-color), 1px 1px 0 #000;
    --shadow-element: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);  
    --shadow-element-medium: 0px 6px 6px 6px rgba(0, 0, 0, 0.25);  
    --shadow-element-strong: 0px 8px 8px 8px rgba(0, 0, 0, 0.25);  

    /* COLORS */
    --primary-color: rgb(172, 125, 78);
    --secondary-color: rgb(83, 68, 60);
    --tertiary-color: rgb(205, 126, 44);
    --background-primary-color: rgb(244, 242, 241);
    --background-secondary-color: rgb(254, 252, 251);
    --text-color-light: rgba(255,255,255,1);
    --text-color-dark: rgb(37, 29, 29);

    color-scheme: only light;
}

.dark__mode {
    --primary-color: rgb(172, 125, 78);
    --secondary-color: rgb(83, 68, 60);
    --tertiary-color: rgb(205, 126, 44);
    --background-primary-color: rgb(37, 29, 29);
    /* Inverted from light mode */
    --background-secondary-color: rgb(47, 39, 39);
    /* Inverted from light mode */
    --text-color-light: rgba(255, 255, 255, 1);
    --text-color-dark: rgb(244, 242, 241);
    /* Inverted from light mode */

    .home {
        background-color: rgba(37, 29, 29, 0.8);
    }

    .contact-form__input::placeholder {
        color: rgba(244, 242, 241, 0.4);
    }

    .nav__link {
        color: var(--text-color-dark);
    }

    .popup {
        background-color: rgba(37, 29, 29, 0.95);
    }

    .footer {
        background-color: rgba(83, 68, 60, 0.7);
        box-shadow: 0px -5px 32px -12px rgb(85, 85, 85);
    }

    .service__title h3 {
        color: var(--text-color-dark);
    }

    .home__name {
        content: url('../img/napis-dark.svg');
    }

    .loading__logo {
        content: url('../img/napis-dark.svg');
    }

    .gmap_iframe {
        filter: invert(90%);
    }

    .swiper-pagination-bullet {
        background: #fff;
    }
}
/* GENERAL STYLES */
* {
    margin: 0;
    padding: 0;
    font-family: var(--font-family-default);
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    color: var(--text-color-dark);
    background-color: var(--primary-color);
    /* Modern syntax with image-set */
    background-image: image-set(
        url('../img/background/background-max.jpg?as=webp') type('image/webp') 1x,
        url('../img/background/background-max.jpg') 1x
    );
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right 0px top 0px;
    box-shadow: inset 0px 0px 600px 200px rgba(0, 0, 0, .8);    
    height: min-content;
    position: absolute;
    width: 100%;
    overflow-x: hidden;
    transition: background-color 1s ease, color 1s ease;
}

*:not(.link, .nav__link *) {
    transition: background-color 1s ease, color 1s ease, fill 1s ease;
}

@media screen and (max-width: 1920px) {
    body {
        background-image: image-set(
            url('../img/background/background-fhd.jpg?as=webp') type('image/webp') 1x,
            url('../img/background/background-fhd.jpg') 1x
        );
    }
}

@media screen and (max-width: 1366px) {
    body {
        background-image: image-set(
            url('../img/background/background-hd.jpg?as=webp') type('image/webp') 1x,
            url('../img/background/background-hd.jpg') 1x
        );
    }
}

@media screen and (max-width: 800px) {
    body {
        background-image: image-set(
            url('../img/background/background-mobile.jpg?as=webp') type('image/webp') 1x,
            url('../img/background/background-mobile.jpg') 1x
        );
    }
}

/* Fallback for browsers that don't support image-set */
@supports not (background-image: image-set(url("") 1x)) {
    body {
        background-image: url('../img/background/background-max.jpg');
    }
    
    @media screen and (max-width: 1920px) {
        body {
            background-image: url('../img/background/background-fhd.jpg');
        }
    }
    
    @media screen and (max-width: 1366px) {
        body {
            background-image: url('../img/background/background-hd.jpg');
        }
    }
    
    @media screen and (max-width: 800px) {
        body {
            background-image: url('../img/background/background-mobile.jpg');
        }
    }
}

body,
button,
input,
textarea {
    font-family: var(--font-family-text);
    font-size: var(--font-size-6);
}

h1,
h2,
h3 {
    font-family: var(--font-family-default);
    font-weight: var(--font-weight-medium);
    color: var(--text-color-light);
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

button {
    cursor: pointer;
}

section {
    scroll-margin-top: 170px;
}

/* COMMONS */
.container {
    max-width: 1240px;
    height: min-content;
    margin: 0 auto;
}

.content {
    max-width: 1140px;
    margin: 0 auto;
}

.main {
    height: min-content;
    background-color: var(--background-primary-color);
}

.title {
    
    font-family: var(--font-family-default);
    font-weight: var(--font-weight-vbold);
    font-size: var(--font-size-3);
    color: var(--text-color-dark);
    border-bottom: 3px solid transparent;
    border-image: linear-gradient(0.55turn, var(--primary-color), var(--secondary-color), var(--primary-color));
    border-image-slice: 1;
    padding-bottom: 0.5rem;
}

.subtitle {    
    align-self: center;
    font-family: var(--font-family-default);
    font-weight: var(--font-weight-vbold);
    font-size: var(--font-size-4);
    color: var(--text-color-dark);  
}

.text {
    line-height: 1.6;
    align-self: center;
    text-align: justify;
    text-justify: inter-word;
    font-family: var(--font-family-text);
    font-weight: var(--font-weight-medium);
    font-size:var(--font-size-5);
    color: var(--text-color-dark);
}

.bold {
    font-weight: var(--font-weight-bold) !important;
    font-family: var(--font-family-text);
}

.btn {
    width: 10rem;
    height: 3rem;    
    margin: .5rem;
    font-size: var(--font-size-6);
    font-family: var(--font-family-default);
    font-weight: var(--font-weight-semilight);
    background-color: var(--secondary-color);
    color:var(--text-color-light);
    letter-spacing: 0.25rem;
    text-transform: uppercase; 
    border-width: 1px;
    border: solid;
    border-radius: 2rem;
    border-color: var(--secondary-color);       
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transition: 0.3s all;
    display: flex;
    align-items: center;
    justify-content: center;    
  }

  .btn.btn__small {
    font-size: .75rem;
    height: 2.5rem;
}

@media (hover: hover) {
  .btn:hover {
    background-color: var(--background-primary-color);
    color: var(--secondary-color);
  }
}

.dark__mode {
    .btn:hover {
        background-color: var(--background-primary-color);
        color: var(--text-color-light);
      }
}

.link:link {
    color: var(--primary-color);
}

.link:visited {
    color: var(--primary-color);
}

.link:hover {
    color: var(--tertiary-color);
}

.link:active {
    color: var(--secondary-color);
}

  .buttons
  {
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
  }

.header__container {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* REUSABLES */
.invisible {
    opacity: 0;
    /* transition: none; */
}

.invisible * {
    opacity: 0;
    /* transition: none; */
}

.hidden {
    display: none !important;
    transition: none;
}

.hidden * {
    display: none !important;
    transition: none;
}

.disabled {
    pointer-events: none;
}

.transitionless, .transitionless * {
    transition: none;
  }

/* NAVBAR */
.nav {
    max-width: 1240px;
    min-width: 1140px;
    flex: 1 1 1140px;
    width: 100%;
    top: 0;
    height: 3rem;
    display: flex;
    align-self: center;
    justify-content: space-between;
    align-items: center;
    background: var(--primary-color);
    border-radius: 3rem;
    margin: 4rem auto;
    position: fixed;    
    z-index: 100;
    box-shadow: 10px 20px 50px rgba(0,0,0,0.35) inset;
    border: 3px solid vaR(--secondary-color);
    transition: .5s all;    
}

.nav__logo {
    min-height: 8rem;
    min-width: 8rem;
    max-height: 10rem;
    max-width: 10rem;
    margin: 1rem;
    border-radius: 50%;  
    position: relative;
}

.nav__logo img {
    height: 100%;
    width: 100%;
    position: absolute;
    border-radius: 50%;
}

.nav__menu {
    display: flex;    
    justify-content: space-evenly;
    list-style: none;
}

#nav__menu-right {
    padding-right: 2rem;    
}

#nav__number {
    font-weight: var(--font-weight-vbold);
}

.nav__menu-item {    
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem;
    margin: 0.3rem;
    white-space: nowrap;
    border-radius: 50px;
}

#nav__menu-left .nav__menu-item {
    width: 8rem;
}

#nav__menu-left :first-child {
    width: auto;
}

.nav__menu-item.active {
    background-color: var(--background-primary-color);
    transition: all 0.5s;    
}

    .nav__menu-item.active .nav__link {
        color: var(--text-color-dark);
    }

.nav__current {
    color: var(--secondary-color);
    height: 1rem;
    padding: 0 0.2rem 0 0;
    align-self: center;
    justify-self: center;
}

.nav__link {   
    font-family: var(--font-family-default); 
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-4);
    color: var(--background-primary-color);
    transition: 0.3s;
}


@media (hover: hover) {
    .nav__link:hover {
        color: var(--secondary-color) !important;
        transition: all 0.3s;
    }
}

.dark__mode {
    @media (hover: hover) {
        .nav__link:hover {
            color: var(--secondary-color) !important;
            transition: all 0.3s;
        }
    }
}


.nav__menu-item.nav__social {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem;
    border-radius: 50px;
  }
  
  .nav__menu-item.nav__social .nav__link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
  }
  
  .nav__menu-item.nav__social .nav__link .fa-stack {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  .nav__menu-item.nav__social .nav__link .fa-stack .fa-square {
    width: 100%;
    height: 100%;
  }
  
  .nav__menu-item.nav__social .nav__link .fa-stack .fa-facebook-f,
  .nav__menu-item.nav__social .nav__link .fa-stack .fa-instagram {
    position: relative;
    color: var(--primary-color);
  }

.fa-inverse {
    color: var(--primary-color) !important;
}

@media (hover: hover) {
.fa-circle:hover {
    color: var(--secondary-color);
}
}

.home {    
    scroll-margin-top: 200px;
    width: 1140px;
    max-height: 600px;
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: center;    
    height: 65vh;
    margin-bottom: 5rem;
    margin-top: 12rem;
    background: rgba(244, 242, 241, 0.8);
    border-radius: 1rem;
    box-shadow: var(--shadow-element);
    backdrop-filter: blur(3px);
    border: 3px solid var(--secondary-color);
    -webkit-backdrop-filter: blur(4px);
    overflow: hidden;
}

.home__heading {
    font-size: 1px !important;
    position: absolute !important;
    left: -9999px !important;
}

.home__swiper {
    --swiper-navigation-color: var(--tertiary-color);    
    --swiper-pagination-color: var(--tertiary-color);
    --swiper-navigation-sides-offset: 100px;
    --swiper-navigation-size: 2rem;    
}



.home__slide {
    display: flex;
}

.home__content
{ 
    display: flex;   
    flex: 1 0 0;  
    flex-basis: 45%;       
    flex-direction: column; 
    padding: 2rem 4rem;
    align-content: center;
    justify-content: space-evenly;
    border-right: 3px solid var(--secondary-color);   
}

.home__title h1 {
    padding: 0 3rem 1rem 0;    
    line-height: 2.5rem;
}

.home__subtitle {
    width: 100%;
    height: min-content;
    border-bottom: 2px solid var(--secondary-color);
    padding-bottom: 1rem;
    align-items: center;
    justify-content: center;
}

.home__name {
    width: 100%;
    height: min-content;
}

.home__subtitle h2 {
    color: var(--secondary-color);
}

.home__text {
    padding: 1rem 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home__text-item {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 1.5rem 1rem;
    user-select: none;
}

.text-arrow {
    height: 1rem;
    line-height: 1rem;
    font-size: var(--font-size-5);
    color: var(--text-color-dark);
}

.arrow-text {
    height: 1rem;
    line-height: 1rem;
    padding: 0 1rem;
    font-size: 1.6rem;
}

.text-arrow-left {
    transform: rotate(45deg);
   
}

.text-arrow-right {
    transform: rotate(225deg);
}


.home__buttons {
    padding: 2rem 0 1rem 0;
}

#home__btn_more {
    background-color: var(--primary-color);
    color: var(--text-color-light);
    border-color: var(--primary-color);
}

@media (hover: hover) {
#home__btn_more:hover {
    background-color: var(--background-primary-color);
    color: var(--primary-color);
}
}


.home__img {
    align-self: stretch;
    flex-basis: 55%;
    /* box-shadow: 0 0 12px 12px rgba(63, 63, 63, 0.7) inset; */
    background-image: image-set(
        url('../img/about/about-max.jpg?as=webp') type('image/webp') 1x,
        url('../img/about/about-max.jpg') 1x
    );
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

@media screen and (max-width: 1920px) {
    .home__img {
        background-image: image-set(
            url('../img/about/about-fhd.jpg?as=webp') type('image/webp') 1x,
            url('../img/about/about-fhd.jpg') 1x
        );
    }
}

@media screen and (max-width: 1366px) {
    .home__img {
        background-image: image-set(
            url('../img/about/about-hd.jpg?as=webp') type('image/webp') 1x,
            url('../img/about/about-hd.jpg') 1x
        );
    }
}

@media screen and (max-width: 800px) {
    .home__img {
        background-image: image-set(
            url('../img/about/about-mobile.jpg?as=webp') type('image/webp') 1x,
            url('../img/about/about-mobile.jpg') 1x
        );
    }
}

/* Fallback for browsers that don't support image-set */
@supports not (background-image: image-set(url("") 1x)) {
    .home__img {
        background-image: url('../img/about/about-max.jpg');
    }
    
    @media screen and (max-width: 1920px) {
        .home__img {
            background-image: url('../img/about/about-fhd.jpg');
        }
    }
    
    @media screen and (max-width: 1366px) {
        .home__img {
            background-image: url('../img/about/about-hd.jpg');
        }
    }
    
    @media screen and (max-width: 800px) {
        .home__img {
            background-image: url('../img/about/about-mobile.jpg');
        }
    }
}

.about {
    display: flex;
    flex-direction: column;
    padding: 2rem 4rem 4rem 4rem;
    min-width: 0;
    user-select: none;
}

.about__content {
    display: flex;
    height: 100%;
    gap: 2rem;
    overflow: hidden;
    margin-top: 2rem;
    min-height: 0;
}

.about__content .about__short {
    border-right: none;
}

.about__short {
    flex-basis: 33%;

    overflow: visible;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--secondary-color) var(--background-secondary-color);
}

.about__short-img {
    height: auto !important;
    width: 200px !important;
    border-radius: 1rem;
}

 .about__photo {
    display: flex;
    flex: 1;
    max-width: 50%;
    overflow: hidden;
    position: relative;
    /* align-self: center; */
    justify-content: center;
    align-items: center;
} 

.about__text {
    flex: 2;
    max-width: 80%;
}

.about__methods {
    padding-right: 2rem;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--secondary-color) var(--background-secondary-color);
}

.about__photo-left {
    border-right: solid 3px var(--secondary-color);    
    padding-right: 2rem;
}

.about__photo-right {
    border-left: solid 3px var(--secondary-color);
    padding-left: 2rem;
}

.about__img {     
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;  
}

.about__timeline {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-height: 100%;
    overflow: scroll;
    overflow-x: hidden;
    overscroll-behavior: contain;
    padding: 0 2rem 0 0;

    scrollbar-width: thin;
    scrollbar-color: var(--secondary-color) var(--background-secondary-color);

  /* Chrome, Edge, and Safari */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: var(--background-secondary-color);
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--secondary-color);
    border-radius: 10px;
    border: 0px solid #ffffff;
  }
}

.about__timeline_item {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    padding: 2rem 0;
    gap: 2rem;
    border-bottom: 1px var(--secondary-color) solid;
}

.about__timeline_item:first-child {
    padding-top: 0;
}

.about__timeline_item:last-child {
    padding-bottom: 0;
    border-bottom: none;
}

.about__timeline-subtitle {
    font-size: var(--font-size-4);
}

.timeline_item-left {
    flex-basis: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.timeline_item-left-img {
    width: 150px;
    max-width: 150px;
    max-height: 150px;
    transition: ease-in-out 0.3s;
    cursor: pointer;
    object-fit: cover;
}

.timeline_item-left-text {
    padding: .2rem;
    font-size: .75rem;
}

.timeline_item-right {   
    flex-basis: 75%;
}

.timeline_item-right-year {
    color: var(--primary-color);
}

.timeline_item-right-title {
    padding-bottom: .3rem;
    font-size: var(--font-size-5);
}

.about__list {
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    align-self: stretch;
}

.about__list-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about__list-icon {
    height: 1.5rem;
}

.about__list-desc {
    font-size: var(--font-size-4);
    font-weight: var(--font-weight-vlight);
    color: var(--text-color-dark);
    padding: 0 0.5rem;
}

.about__paragraph {
    text-indent: 2rem;
}

.about__dog {
    align-self: center;
    flex: 1;
    max-width: 35%;
    height: min-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border-radius: 1rem;
    /* overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: var(--secondary-color) #ffffff; */
}

.about__dog-img {
    padding: 1.5rem 1.5rem 0 1.5rem;
    height: min-content;
    width: 100%;
    border-radius: 1rem;
}

.about__dog-description {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: min-content;

    /* background-color: var(--background-primary-color); */
    backdrop-filter: blur(5px);

    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.about__dog-description p {
    font-family: var(--font-family-handwritten);
    line-height: 1.4;
}

.dog__name {
    font-size: var(--font-size-2);
    font-weight: var(--font-weight-bold);
}

.dog__birthday, .dog__breed {
    font-size: 1.8rem;
}

.dog__birthday i {
    font-size: 1rem;
}



.mail__info {
    
    z-index: 202;



    padding: 2rem 0;
}

.mail__info-title {
    margin-bottom: 1rem;
}

.mail__info-text {
    margin: 1rem;
    font-size:  var(--font-size-4);
    justify-content: center;
    text-align: center;
}

/* SERVICES */
.services {
    height: min-content;
}

.services__wrapper {
    padding: 2rem 0;
    
    transition: all ease 1.5s;
    display: flex;         
    overflow: visible !important;
    
}

.swiper.services__swiper {
    transition: all ease 1.5s;
    height: min-content;
    overflow: hidden !important;    
    margin: 0 1rem 1rem 1rem;
}

.services__swiper-button {
    color: var(--tertiary-color) !important;
    position: relative !important;
}


.another__container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}


.service {
    height: 600px !important;
    padding: 0 .3rem;
    min-width: 270px;
    flex: 0 0 25%;
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;   
    
    transition: 1.5s all !important;
    cursor: pointer;
    user-select: none;   
}

.service__content:hover {
    box-shadow: var(--shadow-element-medium);
    transition: .5s;
}

.service__content {
    height: 600px !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 15px;
    background-color: var(--background-secondary-color);
    border: 3px solid var(--secondary-color);
    box-shadow: var(--shadow-element);
    overflow: hidden;
}

.service__wrapper {
    height: 600px !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.service__title {
    margin-top: 2rem;
    margin-bottom: 1rem;
    min-height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-primary-color-color);
    border-top: var(--primary-color) 5px solid;
    border-bottom: var(--primary-color) 5px solid;


}

.service__title h3 {
    color: var(--secondary-color);
    font-size: var(--font-size-4);
    font-weight: var(--font-weight-vbold);
    text-align: center;
    text-transform: uppercase;
}

.service__picture {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 6px;
    margin: 1rem 0;
}

.service__picture-img {
    height: 10rem;
    border-radius: 6px;
}

.service__list {
    padding: .5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    align-self: stretch;
    margin: auto;

}

.service .service__list-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
}

.service .service__list-icon {
    height: 1.5rem;
    padding: 0 .2rem;
}

.service .service__list-desc {
     font-size: var(--font-size-4);
    color: var(--text-color-dark);
    padding: 0 0.5rem;
    font-weight: var(--font-weight-semibold);
}

.service__more {
    width: 100%;
    padding: 1rem;
    display: flex;
    align-content: center;
    justify-content: center;    
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}


.service__more-btn
{
    width: 7rem;;
    height: 2.5rem;

}

/* SERVICE EXPANDED */

.service__expanded {
    flex: 1 0 100%;
    /* transition: all ease 1.5s; */
}

.service__expanded-loaded {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  
}

.service__expanded__headline {
    display: flex;
    align-items: center;
    gap: 2rem;
    align-self: stretch;
    border-bottom: 3px solid var(--secondary-color);
    padding: 0 0 2rem 0;
    max-height: 250px;
    margin: 2rem 2rem 0 2rem;
}

.service__expanded__headline-picture {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100px;
    border-right: 3px solid var(--secondary-color);
    flex: 2;
}

.service__expanded__headline-picture-img {

    height: 100%;
    max-width: 100%;
    object-fit: contain;

}

.service__expanded__headline-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: min-content;
    height: 100%;
    border-right: 3px solid var(--secondary-color);
    padding-right: 2rem;
    flex: 2;
}

.service__expanded__headline-price {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    min-width: 200px;
    width: 200px;
    border: 2px solid var(--secondary-color);
    border-radius: 1rem;
}

.price-mobile {
    display: none;
}

.service__expanded__price-title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.service .service__expanded-price-icon {
    color: var(--primary-color);
}

.service__expanded__list {
    flex: 1 100%;
    padding: 1rem 2rem;
    margin-top: 2rem;
}

.service__expanded__buttons {
    flex: 1 100%;
}

div[servicename="grupowe"] .service__expanded__headline-price * {
    font-size: var(--font-size-7);
}



.service__expanded-price {
    margin: .5rem;
    font-weight: var(--font-weight-semilight);
    font-size: var(--font-size-5);
    text-align: center;
}

.service__expanded__title {
    font-weight: 1000;
    z-index: 10;
    text-transform: capitalize;
    font-size: var(--font-size-5);
}

.service__expanded__subheadline {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: .8rem;
    font-size: var(--font-size-1);
}

.service__expanded__text {    
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-6);
}

.service__expanded__text-strong {    
    font-weight: var(--font-weight-vbold);
    font-size: var(--font-size-6);
    color: var(--primary-color);
}

.service__expanded__icon {
    display: none;
    /* position: absolute;
    height: 5rem;
    top: 45px;
    margin-left: -80px;
    z-index: 0;
    opacity: .7; */
}

.service__expanded__list {
    flex: 0 1 70%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 1rem;
    flex: 2;
}

.service__expanded__list-item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: .5rem 0;
}

.service__expanded__list-icon {
    margin-right: .8rem;
    height: 1.2rem;
}

.service__expanded__list-desc {
    color: var(--text-color-dark);
    font-size: var(--font-size-6);
}

.service__expanded_buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
}






/* NEWS */

.news__content {
    display: flex;
    gap: 2rem;
    /* grid-template-columns: repeat(3, 1fr); */
    padding: 2rem 0; 
}
   
  .news__card {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      width: auto;      
      min-height: 27rem;
      max-height: 27rem;
      border-radius: .5rem;
      border: 3px solid var(--secondary-color);
      margin: 1rem 0;
      box-shadow: var(--shadow-element);
      overflow:hidden;    
      cursor: pointer;
      flex: 2.5;
      flex-basis: 33,3%;
      flex-shrink: 0;
      transition: 0.5s ease all;
      overscroll-behavior: contain;
      background-color: var(--background-secondary-color);
  }

  .news__card * {
    user-select: none;
  }

  .news__card.active * {
    user-select: auto;
  }

  .news__card * {
    transition: 0.5s ease all;
  }

  @media (hover: hover) {
  .news__card:hover {
    transform: scale(1.02);
    transition: 0.5s ease all;
    box-shadow: var(--shadow-element-strong);
  }
}

  .news__card.active {
    flex: 5;
    /* transform: scale(1); */
    transition: 1s ease all;
    min-height: 25rem;
  }

@media screen and (min-width: 1140px) {
  .news__card.inactive .news__card-cover {
    height: 175px;
  }
}

  .news__card-cover {   
    height: 200px;
    /* transition: 0.5s ease all; */
  }

  .news__card-cover.news__card-cover-hidden {
    height: 0px;
  }

  .news__card.active .news__card-cover {
        height: 0px;   
         /* transition: 0.5s ease all;  */
  }

  .news__card-img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      border-top-left-radius:  .3rem;
      border-bottom-left-radius: .3rem;
  }

  .news__card-data {
    flex: 5;
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem 0 2rem;
    border-top: 3px solid var(--secondary-color);   
    overflow: hidden;
    overscroll-behavior: contain;
    padding: 1rem 1rem 0 1rem;
  }

  .news__card.active .news__card-data {
    flex: 6;
    border: none;
    overflow-y: auto;
    overscroll-behavior: contain;
    scrollbar-width: thin;
    scrollbar-color: var(--secondary-color) var(--background-secondary-color);
    justify-content: flex-start;
  }

  .news__card-date {
      width: 100%;
      margin-bottom: .5rem;
      font-size: var(--font-size-5);
      color: var(--primary-color);
  }

  .news__card-title {
    width: 100%;
      margin-bottom: 1rem;
      font-size: var(--font-size-4);
      text-transform: capitalize;
  }

  .news__card-description {
    width: 100%;
    height: 100%;
    padding: 0;
    flex: 1;
    font-size: var(--font-size-6);
    text-indent: 2rem;
    overflow-wrap: break-word;

    position: relative;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 5rem;
    min-height: 5rem;
    
    /*margin-bottom: auto; */

    /* word-wrap: break-word;
    
    text-overflow: ellipsis; */

    /* max-height: 8rem;
    line-height: 2rem; */
  }

  .news__card.active .news__card-description {
    flex: 0;
    display: block;
    max-height: min-content;
    min-height: auto;
  }
  
   .text__overflowed {
    font-size: var(--font-size-5);
    position: absolute;
    bottom: 0;
    right: 0;
    background: var(--background-secondary-color);
    transition: 0.2s all;
    height: 1.6rem;
    display: none;

  } 

  .news__card.active .news__card-description {
    height: auto;
    overflow: visible;
  }

  .news__card.active .text__overflowed {
    display: none;
  }

  .news__card.active .news__card-read-more {
    display: none;
  }

  .news__card-read-more {
    align-self: flex-end;
    height: min-content;  
    padding: .6rem 0;
    display: block;
    width: 100%;
    text-align: center;
    color: var(--primary-color); 
    align-self: flex-end;
    font-size: var(--font-size-5);
    margin-top: auto;
  }

  .news__card-read-more span {
    font-size: inherit;
    color: inherit;
    transition: 0.2s ease all;
  }

  @media (hover: hover) {
 .news__card:hover .news__card-arrow {
    margin: 0 5px;
  }
}
  
  .news__card-buttons {  
    align-items: center;
    justify-content: center;
    display: none;
    padding: .5rem;
    width: 100%;
    transition: 0.5s ease all;
  }

  .news__card.active .news__card-buttons {
    display: flex;
  }

  .news__card-btn {
    font-size: var(--font-size-6);
    width: min-content;
    padding: 0 1rem;
    height: 2.5rem;
  }

  .news__card-content {
    display: none;
  }

  .news__card.active .news__card-content {
    display: flex;
  }

  .news__card.active .news__card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .news__card.active .news__card-description {
    line-height: 1.8;
  }

  .news__card-list {
    padding: .5rem 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.news__card-list-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
}

.news__card-list-icon {
    height: 1rem;
}

.news__card-list-desc {
    font-size: var(--font-size-6);
    color: var(--text-color-dark);
    padding: 0 0.5rem;
    font-weight: var(--font-weight-medium);
}

.news__card-paragraph {
    text-indent: 2rem;
    font-size: var(--font-size-6);
    width: 100%;
    line-height: 1.8;
}

.news__card-distinct-text {
    padding: .5rem 0;
    font-size: var(--font-size-5);
    line-height: 1.8;
    width: 100%;
    text-align: center;
}



/* SWIPER */
.gallery {
   height: min-content;
}

.gallery__swiper {
    display: flex;
    width: 100%;
    margin: 3rem;
    overflow: hidden !important;
}

.swiper-cards {
    overflow: hidden !important;
}

.gallery__card {
    border-radius: .7rem;
}

.gallery__img {
    width: 100%;
    height: 100%;
}

.gallery__swiper-pagination {
    color: var(--text-color-light);
}

/* OTHERS */

.custom-shape-divider-bottom-1693308080 {
    width: 100%;
    line-height: 0;
    transform: rotate(180deg);
    margin-bottom: -3px;
}

.custom-shape-divider-bottom-1693308080 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 112px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1693308080 .shape-fill {
    fill: var(--background-primary-color);
}


/* SCROLLBAR */
::-webkit-scrollbar {
    width: .7rem;
    background-color: var(--background-secondary-color);
}

::-webkit-scrollbar-thumb {
    border-radius: .2rem;
    background-color: var(--primary-color);
}

/* MEDIA QUERIES */
/* Small screen */
@media screen and (max-width: 320px) {}

/* Medium screen */


/* ANIMATIONS */


/* @keyframes scale {
    to {
        transform: translate(-50%, -50%) scale(3);
        opacity: 0;
    }
} */

@keyframes pulse {
    0% {
        transform:  scale(1);
        opacity: 1;
    }

    30% {
        transform:  scale(1.3);
    }

    100% {
        transform:  scale(1);
        opacity: 1;
    }
}

.pulsing {
    transition: none;
    animation: pulse 0.3s;
}

.pulsing * {
    transition: none;
}

@keyframes fadeIn {
    0% {        
        opacity: 0;
    }

    100% {
        opacity: 1;        
    }
}

.fadingIn {
    transition: none;
    animation-name: fadeIn;
    animation-duration: 1s;
}

.fadingIn * {
    transition: none;
}

.fadingInQuick {
    transition: none;
    animation-name: fadeIn;
    animation-duration: .3s;
}

.fadingInQuick * {
    transition: none;
}

@keyframes fadeOut {
    0% {        
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fadingOut {
    transition: none;
    animation-name: fadeOut;
    animation-duration: 1s;
}

.fadingOut * {
    transition: none;
}

.fadingOutQuick {
    transition: none;
    animation-name: fadeOut;
    animation-duration: .3s;
}

.fadingOutQuick * {
    transition: none;
}

@keyframes fadeOutFadeIn {
    0% {        
        opacity: 1;
    }

    30% {
        opacity: 0;
    }

    90% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fadingOutFadingIn {
    transition: none;
    animation-name: fadeOutFadeIn;
    animation-duration: 1s;
}

.fadingOutFadingIn * {
    transition: none;
}

@keyframes scaleUp {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    70% {
        transform: scale(1.2);
        opacity: 1;
    }

    100% {
        transform: scale(1);     
        opacity: 1; 
    }
}

.scalingUp {
    transition: none;
    animation-name: scaleUp;
    animation-duration: .5s;
}

.scalingUp * {
    transition: none;
}

@keyframes scaleDown {
    0% {
        transform:  scale(1);
    }

    30% {
        transform:  scale(1.2);
        opacity: 1;
    }

    100% {
        transform:  scale(0);;
        opacity: 0;  
    }
}

.scalingDown {
    transition: none;
    animation-name: scaleDown;
    animation-duration: .5s;
}

.scalingDown * {
    transition: none;
}

@keyframes scaleUpAbs {
    0% {
        transform: scale(0) translate(-50%, -50%);
        opacity: 0;
    }

    70% {
        transform: scale(1.2) translate(-50%, -50%);
        opacity: 1;
    }

    100% {
        transform: scale(1) translate(-50%, -50%);     
        opacity: 1; 
    }
}

.scalingUpAbs {
    transition: none;
    animation-name: scaleUpAbs;
    animation-duration: .5s;
}

.scalingUpAbs * {
    transition: none;
}

@keyframes scaleDownAbs {
    0% {
        transform:  scale(1) translate(-50%, -50%);
        opacity: 1;
    }

    30% {
        transform:  scale(1.2) translate(-50%, -50%);
        opacity: 1;
    }

    100% {
        transform:  scale(0) translate(-50%, -50%);    
        opacity: 0;  
    }
}

.scalingDownAbs {
    transition: none;
    animation-name: scaleDownAbs;
    animation-duration: .5s;
}

.scalingDownAbs * {
    transition: none;
}

@keyframes expand {
    0% {
        flex: 1;
    }

    100% {
        flex: 1 0 100%;
    }
}

.expanding {
    transition: none;
    animation-name: expand;
    animation-duration: .7s;
}

.expanding * {
    transition: none;
}

@keyframes slideIn {
    0% {
        transform: translateX(20%);
        opacity: 0;
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

.item-shown {
    transition: none;
    animation-name: slideIn;
    animation-duration: .5s;
}

.item-shown * {
    transition: none;
}

@keyframes slideOut {
    0% {
        transform: translateX(0%);
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateX(20%);
    }
}

.item-hidden {
    transition: none;
    animation-name: slideOut;
    animation-duration: .5s;
}

.item-hidden * {
    transition: none;
}

/* CONTACT */

.contact__content {
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 0;
    margin-top: 1rem;
    gap: 3rem;
}

.contact__info-container {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
}

.contact__form-container {
    display: flex;
    flex-basis: 65%;
    flex-direction: column;
}

.contact__map-container {
    display: flex;
    flex-basis: 100%;
    border-radius: .5rem;
}

.contact__data {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.contact__information {
    display: flex;
    align-items: center;

}

.contact__text {
    font-weight: var(--font-weight-medium);
}

.contact__information-icon {
    width: 2rem;
    font-size: var(--font-size-2);
    color: var(--primary-color);
}

.contact__information-box {
    margin-left: 2rem;
}


.contact__map {
    display: flex;
    align-self: stretch;
    flex-basis: 100%;
    min-height: 400px;
    border: 2px solid var(--secondary-color);
    border-radius: .5rem;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.contact__map-img {
    object-fit: cover;
    max-height: 400px;
    width: 100%;
}

.contact-form {
    display: flex;
    flex-wrap: wrap;
    gap: .8rem;
    flex-basis: 60%;
}


.contact-form__field {
    width: 100%;
    position: relative;
    padding-bottom: 10px;

}

.contact-form__label {
    color: var(--text-color-dark);
    font-size: var(--font-size-4);
    font-weight: var(--font-weight-semibold);
    font-family: var(--font-family-default);
    margin-bottom: 1rem;
}

.contact-form__input {
    background-color: transparent;
    border: 2px solid var(--secondary-color);
    padding: 1rem 1rem 0 1rem;
    outline: none;
    transition: .5s all;
    border-radius: .5rem;
    width: 100%;
    height: 4rem;
    color: var(--text-color-dark);
}

.contact-form__input:focus {
    background-color: var(--background-secondary-color);
    /* border: 1px solid rgba(106, 239, 142, 0.841) !important; */
    box-shadow: var(--shadow-element);
}

.contact-form__input:focus  .contact-form__label {
    color: green;
    /* border-bottom: 1px solid var(--secondary-color) !important; */
}

#name {
    flex-basis: 50%;
    flex: 1 1 0;
}

#email {
    flex-basis: 50%;
    flex: 1 1 0;
}

.contact-form__input--textarea {
    height: 10rem;
    padding-top: 1.8rem;

}

.contact-form__label {
    position: absolute;
    top: 0;
    left: 10px;
    background-color: var(--background-primary-color);
    padding: 0 .3rem;
    font-weight: normal !important;
    border-radius: .4rem;
    color: var(--text-color-dark);

    border-bottom: 2px solid var(--secondary-color);
    border-left: 2px solid var(--secondary-color);
    border-right: 2px solid var(--secondary-color);
    /* border-image: linear-gradient(
      to top, 
      var(--secondary-color), 
      rgba(0, 0, 0, 0)
    ) 2 1%;
    border-width: 2px;
    border-style: solid;
    border-radius: .4rem !important; */
}

.contact-form__input::placeholder {
    color:rgba(0, 0, 0, 0.4);
}


/* POPUP */

.popup {    
    position: fixed;        
    max-width: 85vw;
    max-height: 85vh;
    width: 85vw;
    height: 85vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.image__popup {
    display: none;
    z-index: 110;
}

.image__popup img {
    max-width: 85vw !important;
    max-height: 85vh !important;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    object-fit: contain;
    display: block;
    margin: auto;
    background-color: rgba(223, 224, 223, 0.95);
    border: 3px solid var(--secondary-color);
    border-radius: 10px;
    padding: 1rem;
}



/* @media screen and (min-width: 1140px) {
    .popup {
        max-width: 30vw;
        width: 30vw;
    }
}

@media screen and (max-width: 1140px) {
    .popup {
        max-width: 50vw;
        width: 50vw;
    }
}

@media screen and (max-width: 800px) {
    .popup {
        max-width: 80vw;
        width: 80vw;
    }
}

@media screen and (max-height: 500px) {
    .popup {
        max-width: 320px;
    }
}



@media screen and (max-height: 400px) {
    .popup {
        max-width: 220px;
    }
} */

.loading {
    position: absolute;
    inset: 0; /* Instead of top/left/transform */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    z-index: 200;
    transition: 1s all;
    contain: paint;
  }
  


body .blurry__background {
    transition: 1s all;
}

.loading__logo {
    display: block;
    max-height: 15vh;
    max-width: 70vw;
}

.loading__img {
    width: auto;
    height: auto;
    max-width: 80vw;
    max-height: 40vh;
}

.loading__text {
    font-size: var(--font-size-4);
    color: var(--text-color-light);
    text-shadow: var(--shadow-text-outline);
    padding: 0 2rem;
    text-align: center;
}

.loading__span {
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    float: left;    
    white-space: break-spaces;
}

.loading__span--up {
    transform: translateY(-10px);
}



.blurry__background {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    z-index: 109;
    transition: 1.5s all;
 
}

.blurry__background.blurry__background-strong {
    display: block;
    backdrop-filter: blur(35px);
    background-color: var(--background-primary-color);
    opacity: 0.95;
    z-index: 199;
}

.btn__close-popup {
    color: var(--background-primary-color);
    background-color: var(--secondary-color);
    font-size: var(--font-size-3);
    top: 0;
    right: 0;
    margin-top: -13px;
    margin-right: -13px;
    z-index: 100;
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    border: 1px solid var(--secondary-color);
}

.dark__mode {
    .btn__close-popup {
        background-color: var(--text-color-light);
    }

    .btn__close-popup:hover {
        background-color: var(--secondary-color);
    }
}

@media (hover: hover) {
    .btn__close-popup:hover {
        color: var(--primary-color);
    }
}


/* FOOTER */


.footer {
    background-color: rgba(83, 68, 60, 0.7);
    border-top: 3px var(--primary-color) solid;
    box-shadow: 0px -5px 32px -12px rgba(66, 68, 90, 1);
}

.footer__container {
    padding: 2rem;
    padding-bottom: 4rem;
    display: flex;
    justify-content: space-between;
}

.footer__subtitle {
    margin-bottom: 1.5rem;
    font-size: var(--font-size-4);
    color: var(--text-color-light);
}

.footer__list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.footer__link {
    display: flex;
    align-items: center;
    gap: .6rem;
    font-size: var(--font-size-6);
    color: var(--text-color-light);
    transition: all 0.3s;
}

@media (hover: hover) {
.footer__link:hover {
    color: var(--primary-color);
}
}

.footer__link-icon {
    width: 1.5rem;
    color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.service__expanded .service__wrapper {
    overflow-x: hidden;
    overflow-y: auto;  
    scrollbar-width: thin;
    scrollbar-color: var(--secondary-color) var(--background-secondary-color);
    overscroll-behavior: contain;
}



@media screen and (max-width: 1240px) {
    .nav {
        min-width: min-content;
        width: auto;
    }
    
    .news__title, .services__title, .gallery__title, .calendar__title, .contact__title {
        margin: 0 2rem;
    }
    
    .news__content {
        margin: 0 2rem;
    }

    .contact__content {
        margin: 0 2rem;
    }
  }

  @media screen and (max-width: 1140px), (max-height: 500px) {    
    #nav__menu-left {
        height: auto;        
        flex-direction: column;
    }

    .nav__toggle {
        display: flex;
        cursor: pointer;
    }

    .contact__content {
        padding: 2rem;
    }

    .swiper-button-next.services__swiper-button-next {
        right: 8px;
    }

    .swiper.services__swiper {
        margin: 0 1rem;
    }
  }

@media screen and (max-width: 1240px) {
    .swiper-button-prev.services__swiper-button-prev {
        left: 8px;
    }

    .swiper-button-next.services__swiper-button-next, .swiper-button-prev.services__swiper-button-prev {
        --swiper-navigation-size: 22px;
    }
}

@media screen and (max-width: 1140px), (max-width: 1400px) and (max-height: 650px) {
    .about {
        min-height: 0;
    }    

    .about__content {
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: var(--secondary-color) var(--background-secondary-color);
        overscroll-behavior: contain;
        padding: 0 2rem;
    }

    .about__short {
        max-height: min-content;
        overflow-y:visible;
        padding: 0;
        padding-bottom: 2rem;
        border-bottom: 3px solid var(--secondary-color);        
    }

    .about__short-text {
        min-height: auto;
        height: min-content;
        text-indent: 2rem;
    }

    .about__timeline {
        flex: 0 0 100%;
        overflow: visible;
        scrollbar-width: none;
        scrollbar-color: none;  
        padding: 0;
    }

    .about__photo {
        flex: 0 0 auto; 
        width: 100%;
        max-width: 100%;
        height: 100%;
        
        min-height: 0;
        order: 2;
    }    

    .about__img {
        max-width: 95%;
        max-height: 100%;
    }


    .about__photo-left {
        border-right: none;
        padding-right: 0;
    }

    .about__photo-right {
        border-left: none;
        padding-left: 0;
    }

    .about__content .about__dog {
        -webkit-box-ordinal-group: 1;
        -moz-box-ordinal-group: 1;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1;
    }

    .about__content .about__dog {
        -webkit-box-ordinal-group: 2;
        -moz-box-ordinal-group: 2;
        -ms-flex-order: 2;
        -webkit-order: 2;
        order: 2;
    }

    .about__text {
        padding-right: 0;
    }

    .about__methods {
        overflow: visible;
        width: 100%;
    }

    .about__content {
        padding: 0 2rem 0 0;
    }

    .about__text{
        max-width: 100%;
        width: 100%;
    }
}

@media screen and (max-width: 1140px) {
        /* overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: var(--secondary-color) #ffffff; */

    .nav {
        width: 570px;       
    }

    .nav-toggled {
        height: 21rem;
    }

    .home {
        margin-left: auto;
    }

    .home__slide {
        flex-direction: column;
    }

    .home {
        max-width: 570px;
    }

    .home__img {
        display: none;
    }

    .news__content {
        align-items: center;
        flex-direction: column;
        gap: 0;
    }

    .news__card {
        
        max-width: 500px;  
    }

    .news__card-data {
        max-height: 15.5rem;
        height: 15.5rem;
    }

    .news__card.active .news__card-data {
        max-height: none;
        height: min-content;
    }

    .calendar__content {
        margin: 0 2rem;
    }

    #full__calendar {
        margin: 0 2rem;
    }

    .contact__content {
        margin: 0 2rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contact__form-container {
        width: 100%;
    }

    .contact__map-container {
        width: 100%;
    }

    .contact__data {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .contact__information {
        width: 47%;

     
    }

    .service {
        flex: 0 0 33.33%;
    }

    .news__card.active .news__card-data {
        overflow: visible;
    }

    .service__expanded-loaded {        
        flex-direction: column;
    }

    .service__expanded {
        flex: 0 0 100%;
    }

    .service__expanded__headline-picture {
        padding-right: 2rem;
    }

    .service__expanded .service__content {
        padding: 0rem;        
        overflow: hidden;
    }

    .nav__toggle {
        width: 3rem;
        margin-left: 2rem;
        font-size: var(--font-size-4);
        display: block;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .5rem;
    }
  } 

  @media screen and (max-width: 800px) and (max-height: 700px) {
    .service__list-desc {
        font-size: var(--font-size-4);
    }

    .service__list-icon {
        height: 1.5rem;
    }

    .service__list-item {
        padding: 0.7rem;
    }
  }

  @media screen and (max-width: 800px) and (max-height: 850px) {
    .service__picture-img {
        height: 15rem;
    }
  }

  @media screen and (max-width: 700px) {
    .contact__data {
        flex-direction: column;
    }

    .contact__information {
        width: 100%;
    }
  }

  @media screen and (max-width: 400px) {
    .service .service__expanded__headline-description {
        padding: 0;
    }

    .service__expanded__list-icon {
        height: 1rem;
    }

    .service__expanded__list {
        padding: 1rem;
    }

    .contact__information-box {
        margin-left: 1rem;
    }

    .contact__information-box .subtitle {
        font-size: var(--font-size-5);
    }

    .contact__information-icon {
        font-size: var(--font-size-3);
    }
  }

  @media screen and (max-width: 800px) {
    .service {
        flex: 0 0 50%;
    }    

    .service__expanded {
        flex: 0 0 100%;
    }

    .service__expanded-price {
        align-self: center;
        font-size: var(--font-size-4);
        width: 100%;
    }
  }

  @media screen and (max-width: 900px) {


    .service__expanded__headline {
        flex-direction: row;
        flex-shrink: 0;
        max-height: min-content;
        height: min-content;
        justify-content: center;
        gap: 1rem;
   
        border-bottom: none;
        flex-wrap: wrap;
       
    }

    .service .service__expanded__title {
        margin: 0 .5rem .5rem .5rem;
        font-size: var(--font-size-5);
    }

    .service__expanded__subheadline {
        width: 100%;
    }

    .service__expanded__headline-picture {
        
        align-self: flex-start;
        width: 45%;
        min-width: 200px;
        max-height: 200px;
        height: min-content;
        padding: 0 1rem 0 1rem;
        border-right: none;
        flex: 0;
    }

    .service__expanded__list {
        justify-content: flex-start;
    }

    .service__expanded__headline-picture-img {
        height: 100%;
    }

    .service__expanded__icon {
        display: none;
    }



    .service__expanded__headline-description {
        flex-direction: column;
        flex: 1 50%;
        width: 100%;
        height: min-content;
        padding: 0;
        border-right: none;
        justify-content: flex-start;
        align-items: center;
        /* -webkit-box-ordinal-group: 3;
        -moz-box-ordinal-group: 3;
        -ms-flex-order: 3;
        -webkit-order: 3; */
        order: 2;
        border-top: none;
        margin: 1rem 0 .5rem 0;

    }



    .service__expanded__title {
        text-align: center;
        margin: 1rem 0;
        width: 100%;
        font-size: var(--font-size-4);
    }

    .service__expanded__headline {
        padding: 0;
    }

    .service__expanded__list {
        margin-top: 0;
    }
    
    .timeline_item-right {
        flex-basis: 100%;
    }

    .arrow-text {
        padding: 0 1.5rem;
    }
  }

  @media screen and (max-width: 800px) and (max-height: 650px) {
    .service__expanded__headline-description {
        order: 0;
    }
  }

  @media screen and (max-width: 600px) {
    .service__expanded__headline {
        margin-top: 1rem;
    }
  }

@media screen and (min-width: 600px) and (max-width: 800px) and (max-height: 650px) {
    

    .service__expanded__text {
        max-width: 80%;
    }
}

@media screen and (max-width: 500px) {
    .swiper .home__content {
        padding: 1rem;
    }

    .content {
        margin: 0;
    }
}


@media screen and (max-height: 600px) and (max-width: 600px) {
    .home__content {
        padding: 2rem;
    }

    .about {
        padding: 2rem;
        padding-top: 1rem;
    }


    .swiper-button-next.home__swiper-button-next {
        top: 96%;
    }

    
    .swiper-button-prev.home__swiper-button-prev {
        top: 96%;
    }

    .swiper.home__swiper {
        padding-bottom: .5rem;
        --swiper-navigation-size: 1rem;  
    }

    .home__content {
        border-right: none;
    }
}

@media screen and (max-width: 600px) and (max-height: 600px) {
    .home .home__text-item {
        padding: .5rem 1rem;
    }
}

@media screen and (max-width: 600px), (max-height: 650px) {
    .home {
        margin-left: 1rem;
        margin-right: 1rem;;
    }

    .home__buttons {
        padding-bottom: 0;
    }

    .btn.home__btn {
        padding: 0 1rem;
    }

    .swiper-button-next.home__swiper-button-next {
        top: 96%;
        z-index: 99;
        height: 2rem;
        width: 2rem;
        padding-left: 1rem;
        padding-bottom: .5rem;
    }

    
    .swiper-button-prev.home__swiper-button-prev {
        top: 96%;
        height: 2rem;
        width: 2rem;
        padding-right: 1rem;
        padding-bottom: .5rem;
    }

    .swiper-pagination.home__swiper-pagination {
        left: 50% !important;
        transform: translate(-50%, 0);
        width: 50% !important;
        bottom: 5px !important;
    }

    
    /* .swiper-button-prev.home__swiper-button-prev::before, .swiper-button-next.home__swiper-button-next::before {
        content: ''; 
        width: 4rem; 
        height: 3rem; 
        position: absolute;
        cursor: pointer;
        left: 0;
        pointer-events: auto;
        z-index: -9999;
        background-color: transparent;

    }
    
    .swiper-button-prev.home__swiper-button-prev::after, .swiper-button-next.home__swiper-button-next::after {
        content: ''; 
        width: 4rem; 
        height: 3rem; 
        position: absolute;
        right: 0;
        cursor: pointer;
        pointer-events: auto;
        z-index: -999;
        background-color: transparent;

    }  */



    .swiper.home__swiper {

        --swiper-navigation-size: 1rem;  
    }

    .swiper.gallery__swiper {
        padding: 0 5rem;
    }

    .about {
        padding: 1rem;
        padding-bottom: 2rem;
    }

    .about__content {
        padding: 0 1rem;
    }
}


@media screen and (max-width: 600px) {    

    .nav {
        width: auto;
        min-width: 280px;
        padding: 1rem;
    }

    #nav__menu-right {
        display: none;
    }

    #nav-toggle {
        padding: 1rem;
        margin-left: 0;
    }

    .nav-toggled {
        height: 25rem;
        flex-direction: column;   
    }

    .nav-toggled .nav__logo {
        display: none;
    }

    .arrow-text {
        height: min-content;
        text-align: left;
        line-height: 1.5rem;
    }

    .home__text {
      height: min-content;
    }

    .home__text-item {
        padding: .8rem 1rem;
    }

    .home__buttons {
        width: 50%;
    }
    
    .home__content {
        min-width: 0;
        flex-basis: 0%;
        padding: 2rem;
    }

    

    .about__timeline_item {
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }   

    .service__expanded__headline-picture {
        width: 100%;
        min-width: 200px;
        order: 0;
    }

    .gallery__swiper {
        margin: 2rem;
    }

    .contact__content {
        padding: 2rem;
    }

    .news__card {
        max-height: 500px;
    }

    
    .news__card.active {
        max-height: min-content;
    }

    .footer__container {
        flex-direction: column;
      
        gap: 2rem;
    }

    .service {
        flex: 0 0 100%;
    }

    .contact__information {
        width: 100%;
    }

    .news__card-buttons {
        flex-wrap: wrap;
    }


}

@media screen and (max-width: 600px) and (min-width: 500px) {
    .news__card {
        width: 100%;
    }
}

@media screen and (max-width: 800px) and (min-height: 500px) {
    .arrow-text {
        font-size: 1.8rem;
        line-height: 2.5rem;
        height: auto;
    }
}

@media screen and (max-width: 460px) {
    .arrow-text {
        font-size: 1.5rem;
        line-height: 2rem;
    }


}

@media screen and (max-width: 500px) {
    .home__name {
        width: 100%;
    }

    .news__card {
        margin: 1rem 2rem;
    }
}


    @media screen and (max-width: 400px) {
        .home__text-item {
            padding: .8rem 0rem;
        }    

        .about__short {
            flex: 1 1 100%;
            height: min-content;
            overflow: visible;

        }

        .timeline_item-right-text {
            text-justify: auto;
        }

        .news__card {
            min-width: 0;
       
        }

        .news__card-list-item {
            width: 100%;
            align-items: center;
            justify-content: flex-start;
        }

        .news__card-read-more {
            font-size: var(--font-size-6);
        }

        .news__card-data {
            padding: 1rem 1rem 0 1rem;
        }

        .news__card-description {
            min-width: 0;
        }

        .swiper.gallery__swiper {
            padding: 0 2.5rem;
        }

        .btn.home__btn {
            font-size: var(--font-size-7);
            width: 7rem;
        }   


    }

    @media screen and (max-height: 927px) {
        /* .home__text-item {
            padding: 0.7rem 1rem;
        } */

        .home__subtitle {
            display: flex;
        
            flex: 0 1 auto;
            height: auto;
            width: auto;
            min-height: 0;
            min-width: 0;      
            overflow: hidden;
            min-height: 70px;
        }

        .home__name {
            height: 100%;
            align-self: center;
            object-fit: contain;
        }
        .home__img {
            flex: 1 1 75%;
        } 


        .home__text {
            height: min-content;
        }

        .home {
            margin-top: 11rem;
            max-height: 70vh;
        }


    }

    .service .service__list-desc {
        font-size: var(--font-size-5);
    }

    .service .service__list-item {
        padding: 0.5rem;
    }

    .service .service__list-icon {
        height: 1rem;
    }


        @media screen and (min-height: 850px) and (max-width: 950px)  {
            .service .service__list-desc {
                font-size: var(--font-size-6);
            }
        
            .service .service__list-item {
                padding: 0.5rem;
            }
        
            .service .service__list-icon {
                height: 0.9rem;
            }
        }

        @media screen and (min-height: 850px) and (max-width: 600px)  {
            .service .service__list-desc {
                font-size: var(--font-size-5);
            }
        
            .service .service__list-item {
                padding: 0.5rem 0;
            }
        
            .service .service__list-icon {
                height: 1.2rem;
            }
        }

        @media screen and (max-height: 850px) and (min-width: 800px) {
            .service__picture {
                display: none;
            }
        }

        @media screen and (max-height: 850px) and (max-width: 800px) {
            .service .service__list-desc {
                font-size: var(--font-size-4);
            }
        
            .service .service__list-item {
                padding: 0.7rem;
            }
        
            .service .service__list-icon {
                height: 1.3rem;
            }
        }

        @media screen and (max-height: 650px) {
            .service .service__list-desc {
                font-size: .9rem;
            }
        
            .service .service__list-item {
                padding: 0.3rem;
            }


        
            .service .service__list-icon {
                height: .9rem;
            }

            .service .service__more {
                padding: .3rem;
            }
        }

        @media screen and (max-height: 650px) and (max-width: 800px) {
            .service .service__list-desc {
                font-size: var(--font-size-4);
            }
        
            .service .service__list-item {
                padding: 0.5rem;
            }
        
            .service .service__list-icon {
                height: 1.3rem;
            }

            .service .service__more {
                padding: .5rem;
            }
        }

        @media screen and (max-height: 500px) {
            .service .service__list-desc {
                font-size: var(--font-size-4);
            }
        
            .service .service__list-item {
                padding: 0.5rem;
            }
        
            .service .service__list-icon {
                height: 1.3rem;
            }
        }

    @media screen and (max-height: 850px) and (max-width: 800px), (max-height: 500px) {
        :root {
            /* FONT SIZES */
            font-size: 12px;
        }

        .service__title h3 {
            font-size: var(--font-size-4);
        }
    }

    @media screen and (max-height: 850px) and (min-width: 800px) {
        .service__expanded .service__wrapper {
            flex-direction: row;
        }

        .service__expanded .service__content {
            padding-bottom: 1rem;
        }

        .service__expanded__headline {
            flex: 1;
            flex-direction: row;
            border-bottom: none;
            max-height: 100%;
    
            padding: 0;
            height: min-content;
            margin: 2rem 1rem;
            flex-wrap: wrap;
        }

        .service__expanded__headline-picture-img {
            max-width: 80%;
        }

        .service.service__expanded * {
            font-size: 16px;
        }

        .service .service__expanded__btn {
            font-size: var(--font-size-7);
            padding: 1rem 0;
        }

        .service__expanded__headline-picture {
            border-right: none;
            width: 12rem;
        }

        .service__expanded__headline-price {
            position: absolute;
            margin: 1rem 1.5rem;
            top: 0;
            right: 0;
        }

        .service__expanded__headline-description {
            border-right: none;
            width: auto;
            padding-right: 0;
        }

        .service__expanded_buttons {
            position: absolute;
            bottom: 0;
            width: min-content;
            right: 0;
            margin: 1rem;
            
        }

        .service__expanded__list {
            margin-top: 0;
            justify-content: flex-start;
            margin-right: 220px;
            padding: 1rem;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        .service__expanded__headline-description {
            margin: 0 .5rem;
        }

        .service .service__expanded__title {
            margin: 0;
        }
    }

    @media screen and (max-height: 850px) and (min-width: 800px) {
        .nav {
            margin: 3rem auto;
        }

        .home {
            max-height: 75vh;
            height: 75vh;
            margin-top: 10rem;
        }


        /* --font-size-0: 3.75rem;
        --font-size-1: 2.75rem;
        --font-size-2: 2.25rem;
        --font-size-3: 1.85rem;
        --font-size-4: 1.05rem;
        --font-size-5: .85rem;
        --font-size-6: .75rem;
        --font-size-7: .6rem;
        --font-size-8: .25rem; */
        
/* 
        .service__list-desc {
            font-size: var(--font-size-5);
        } */

        .about__short-text {
            min-height: auto;
        }

        .service__content {
            height: 500px !important;
        }

        .service {
            height: 500px !important;
        }

        .service__list-desc {
            font-size: var(--font-size-5);
        }




    }

    @media screen and (max-height: 650px) {

        .service__picture  {
            display: none;
        }

        .service__expanded__headline-picture {
            display: none;
        }

        
        .service__content {
            height: 300px !important;
        }

        .services__wrapper {
            height: 300px !important;
        }

        .service {
            height: 300px !important;
        }


        .service.service__expanded * {
            font-size: 12px;
        }
    }

    @media screen and (max-height: 850px) {
        .service__expanded__headline-picture {
            max-height: 200px;
            min-width: 200px;
            padding: 1rem 0;
        }
    }

    @media screen and (max-height: 850px) and (min-width: 800px) {        
        .service__expanded_buttons {
            flex-direction: column;
        }
    }

    @media screen and (max-height: 500px) and (max-width: 1140px) {
        .home__buttons {
            display: none;
        }
    }

    @media screen and (max-height: 500px) and (min-width: 1140px) {
        .home {
            margin-left: 10rem;
            margin-right: 10rem;
            width: auto;
        }
    }

    @media screen and (max-width: 800px) {
        .home {
            min-height: 300px;
        }

        .service__expanded__list-item {
            padding-left: 1rem;
            padding-right: 1rem;
        }

        .home__text-item {
            padding: 1rem;
        }
    }

    @media screen and (max-height: 750px) and (max-width: 1140px) {
        .home__buttons {
            display: none;
        }

    }

    @media screen and (max-height: 700px), (max-width: 800px) {
        .service * {
            font-size: 12px;
        }

        .service__title {
            margin-top: 1.5rem;
        }

        .home__buttons {
            display: none;
        }



        .service__more-btn.btn__small {
            height: min-content;
            width: min-content;
            padding: .3rem .5rem;
        }

        .service__expanded__btn.btn__small {
            height: 2rem;
            width: 8rem;
            padding: .5rem .5rem;
        }



    }

    @media screen and (max-height: 400px) and (max-width: 1140px) {
        .home__subtitle .home__name {
            width: 70%;
        }
    }

    @media screen and (max-height: 360px) and (max-width: 1140px) {
        .home__text .home__text-item {
            padding: .8rem;
        }
    }

    @media screen and (max-height: 650px) and (min-width: 500px) {
        .home__name {
            width: 45%;
        }

        .home__text-item {
            padding: .8rem;
        }
    }

    @media screen and (max-height: 500px) and (min-width: 600px) {
        .news__content {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    @media screen and (max-width: 560px) {
        .price-normal {
            display: none;
        }

        .price-mobile {
            display: flex;
            align-self: center;
            margin: 1rem 0;
        }
    }

    @media screen and (max-height: 500px) and (min-width: 550px), (min-width: 600px) and (max-width: 1140px)  {
        .news__card {
            flex-direction: row;
            min-width: 500px;
            max-width: 60%;

            height: 16rem;
            max-height: 16rem;
            min-height: 16rem;
            
        }

        .news__content {
            flex-direction: column;
            align-items: center;
            gap: 0;
        }

        .news__card-data {
            border-top: none;
        }

        .news__card-cover {
            height: auto;
            width: 50% ;
            border-right: 3px solid var(--secondary-color); 
        }

        .news__card.active {
            flex-direction: column;
            height: 70vh;
            max-height: 70vh;
        }

        .news__card.active .news__card-data  {
            width: 100%;
            overflow-y: auto;
        }

    }

    @media screen and (min-height: 651px) and (min-width: 1141px)  {
        .nav__toggle {
            display: none;
        }
    }

    @media screen and (max-height: 650px) and (min-width: 800px) {
        .home {
            margin-top: 1rem;
            max-height: none;
            height: calc(100vh - 2rem)
        }

        .home__name {
            width: 100%;
        }

        .home__subtitle {
            padding-bottom: 2rem;
        }

        .home__content {
            padding-top: 1rem;
            justify-content: center;
        }

        .text-arrow {
            font-size: var(--font-size-4);
        }

        /* .arrow-text {
            font-size: var(--font-size-3);
        }         */

        .home__text-item {
            padding: 1.5rem 1rem;
        }
        


        .nav__toggle {
            margin-left: 0;
            margin: 1rem;
        }
    
        .nav.nav-toggled {
            width: 9rem;
            
            overflow: hidden;
        }

        .nav-toggled .nav__logo {
            display: none;
        }

        .nav__menu {
            flex-direction: column;
        }
    
        .nav {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 4rem;
            min-width: auto;
            height: 90%;
            left: 2rem;
            top: 50%;
            transform: translate(0, -50%);
            padding: 1rem 0;
            margin: 0;
        }      

    }

    @media screen and (min-height: 650px) and (max-width: 1140px) {
        /* .home__name {
            width: 70%;  #todo
        } */

        .home__subtitle {
            padding-bottom: 2rem;
        }

        .home__content {
            padding-top: 1rem;
            justify-content: center;
        }

        .text-arrow {
            font-size: var(--font-size-4);
        }

        .arrow-text {
            font-size: 1.75rem;
        }        

        /* .home__text-item {
            padding: .8rem 1rem;
        } */
    }


    @media screen and (max-width: 600px), (min-width: 800px) and (max-height: 650px){
        #nav__menu-right {
            display: none;
        }    
        #nav__toggle {
            padding-left: 0;
            align-self: center;
        }
    
    }

    @media screen and (min-width: 1140px) and (max-width: 1240px) and (min-height: 650px) {
        #nav__menu-right {
            display: none;
        }   
    }

    @media screen and (max-width: 1400px) and (max-height: 650px) {
        .home__img {
            display: none;
        }

        .home {
            max-width: 570px;
        }
    }



/* ========== 4k =============== */

@media screen and (min-width: 2500px) {
    * {
        font-size: 32px;
    }

    .nav {
        max-width: 78rem;
        min-width: 72rem;
        flex: 1 1 72rem;
        width: 100%;
    }

    .content {
        max-width: 72rem;
        margin: 0 auto;
    }

    .home {    
        scroll-margin-top: 11rem !important;
        margin-top: 11rem !important;
        margin-bottom: 3rem !important;
        width: 72rem;
        max-height: 38rem;
    }

    .about__short-img {
        width: 13rem !important;
    }

    .timeline_item-left-img {
        width: 10rem;
        max-width: 10rem;
        max-height: 10rem;
    }

    .news__card-cover {
        height: 13rem;
    }

    .service__expanded__headline {
        max-height: 16rem;
    }

    .service__expanded__headline-price {
        min-width: 13rem;
        width: 13rem;
    }

    .gallery__swiper {
        margin-top: 2rem;
    }
/* 
    .fa-square {
        font-size: 1.5rem;
    }

    .fa-stack {
        max-width: 2rem;
        height: 74px;
    }

    .fa-facebook-f {
        font-size: 1rem;
    }

    .fa-instagram {
        font-size: 1rem;
    } */
    
    .swiper-pagination-bullet {
        width: .5rem !important;
        height: .5rem !important;
    }

    .service__expanded__headline-picture {
        width: 10rem;
    }

    .container {
        max-width: 78rem;
        height: min-content;
        margin: 0 auto;
    }

    .service {
        height: 38rem !important;
    }

    .service__content {
        height: 38rem !important;

    }
    
    .service__wrapper {
        height: 38rem !important;
    }
}

.dark__mode-toggle {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    background-color: var(--primary-color);
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    border: 3px solid var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .5s all;
    z-index: 99;
}

.dark__mode-toggle:hover{
    .dark__mode-icon {
        color: var(--secondary-color);
    }
}

.dark__mode-icon {
    font-size: var(--font-size-4);
    color: var(--background-primary-color);
}

/* FULLCALENDAR */

 .fc-day {
    max-height: 30px !important; /* Set your desired max height */ 
  }

  .fc-toolbar-title {
    color: var(--text-color-dark);
  }

  .fc .fc-today-button {
    background-color: var(--secondary-color);
  }

  #full__calendar {
    padding: 1rem 0;
    /* max-height: 70vh; 
    height: 70vh; */
    height: min-content;
    overflow-y: auto;  /* Add overflow-y to enable scrolling if needed */
    --fc-header-background: var(--primary-color); /* Header background color */
    --fc-header-color: #fff !important; /* Header text color */
    --fc-today-background:  var(--primary-color); /* Today button background color */
    --fc-today-color: #fff  !important;/* Today button text color */
    margin: 1rem 0;
  }


    .fc-day {
        color: var(--text-color-dark) !important;
      }

      .fc-col-header-cell  {
        color: var(--text-color-light) !important;
      }


  .calendar__content {
    padding: 2rem 0;

  }

  /* @media screen and (max-height: 700px) {
    #full__calendar {
        max-height: 90vh;
        height: 90vh;
    }
  }

  @media screen and (max-height: 430px) {
    #full__calendar {
        max-height: 95vh;
        height: 95vh;
    }
  } */

  @media screen and (max-width: 1240px) {
    .calendar__content {
        margin: 0 2rem;
      }
  }

  @media screen and (max-width: 1140px) {


    .contact__content {
        margin: 0;
    }
  }


  

  /* 

  .fc .fc-button-primary {
    background-color: var(--secondary-color) !important; 
    border-color: var(--text-color-dark) !important;
  }

  .fc .fc-day-top {
    background-color: var(--secondary-color) !important; 
  }
 

  .no__events-day {
    background-color: #000;
  } */

  :root {
    --fc-small-font-size: .85em;
    --fc-page-bg-color: var(--background-primary-color);
    --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
    --fc-neutral-text-color: #808080;
    --fc-border-color: rgba(83, 68, 60, 0.35);
  
    --fc-button-text-color: var(--text-color-light);
    --fc-button-bg-color: var(--secondary-color);
    --fc-button-border-color: var(--text-color-dark);
    --fc-button-hover-bg-color: var(--background-primary-color);
    --fc-button-hover-border-color: var(--text-color-dark);
    --fc-button-active-bg-color: var(--background-primary-color);
    --fc-button-active-border-color: var(--text-color-dark);
  
    /* --fc-event-bg-color: #3788d8;
    --fc-event-border-color: #3788d8;
    --fc-event-text-color: #fff;
    --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25); */
  
    /* --fc-more-link-bg-color: #d0d0d0;
    --fc-more-link-text-color: inherit;
  
    --fc-event-resizer-thickness: 8px;
    --fc-event-resizer-dot-total-width: 8px;
    --fc-event-resizer-dot-border-width: 1px; */
  
    /* --fc-non-business-color: rgba(215, 215, 215, 0.3);
    --fc-bg-event-color: rgb(143, 223, 130); */
    --fc-bg-event-opacity: 0.3;
    --fc-highlight-color: rgba(188, 232, 241, 0.3);
    --fc-today-bg-color: rgba(83, 68, 60, 0.5);
    /* --fc-now-indicator-color: red; */
  }

  .fc .fc-button-primary:hover {
    color: var(--secondary-color) !important;
  }

  .fc .fc-col-header-cell {
    background-color: var(--secondary-color);
    color: var(--text-color-light);
  }



  .fc .fc-day-other {
    /* background-color: rgba(230, 230, 230, 1); /* Grey out background */ 
    position: relative;
  }
  
  .fc .fc-day-other::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../img/crossDay.png'); /* Your PNG */
    background-size: 100% 100%; /* Adjusts the image size */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* No repeat */
    z-index: 0; /* Ensure the image is above other content */
  }

  .fc .fc-day {
    transition: none;
  }

  .dark__mode {
    .fc .fc-day-other {
        background-color: rgba(230, 230, 230, .15)
      }

    .fc .fc-day-other::before {
        background-image: url('../img/crossNight.png'); /* Your PNG */
    }

    .fc .fc-day-today {
      background-color:  rgba(205, 126, 44, 0.3) !important;
      }
  }

@media screen and (max-width: 400px) {
  .fc .fc-toolbar-title {
    font-size: 1.3rem !important;
  }

  .fc .fc-button {
    font-size: 1.3rem !important;
}
}

@media screen and (max-width: 400px) and (min-height: 850px) {
    .fc .fc-toolbar-title {
      font-size: .9rem !important;
    }

    .fc .fc-button {
        font-size: .9rem !important;
    }
  }


  .calendar__info {
    display: flex; 
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
  }

  .calendar__disclaimer {
    display: flex;
    align-items: center;
    max-width: 70%;
  }

  @media screen and (max-width: 800px) {
    .calendar__disclaimer {
        max-width: 100%;
    }
  }

  @media screen and (max-width: 500px) {
    .calendar__disclaimer {
        max-width: 100%;
    }
  }

   .calendar__info-icon, .contact__info-icon, .services__info-icon {
    color: var(--primary-color);
    font-size: var(--font-size-3);
    margin-right: 2rem;
  }


  .legend__red, .legend__green {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .legend__red .legend__square {
    background-color: #b8442b;
    height: 1rem;
    width: 2rem;
    margin-right: 1rem;
  }

  .legend__green .legend__square {
    background-color: #008151;
    height: 1rem;
    width: 2rem;
    margin-right: 1rem;
  }

  .contact__info {
    display: flex;
    align-items: center;
  }

  .services__info {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 2rem 3rem 0 3rem;
  }

  @media screen and (max-width: 1140px) {
    .services__info {
        margin: 2rem 2rem 0 2rem;
      }
  }

  .calendar-container {
    max-width: 1140px;
    margin: 1rem auto;
    width: 100%;
    box-sizing: border-box;
}

.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    background-color: var(--background-primary-color);
}

.calendar-title {
    font-size: var(--font-size-3);
    color: var(--text-color-dark);
}

.calendar-nav button {
    padding: .5rem 1rem;
    margin: 0 .5rem;
    background-color: var(--secondary-color);
    color: var(--text-color-light);
    border: none;
    cursor: pointer;
    font-size: var(--font-size-4);
}

.calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    border: 1px solid #53443c59;
}

.calendar-header-cell {
    padding: 10px;
    text-align: center;
    background-color: var(--secondary-color);
    color: var(--text-color-light);
    border: 1px solid #53443c59;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-4);
}

.calendar-cell {
    min-height: 100px;
    border: 1px solid #53443c59;
    padding: .3rem;
    position: relative;
    min-width: 0;
    /* Allows cell to shrink below content size */
}

.date-number {
    /* position: absolute;
    top: 5px;
    right: 5px; */
    text-align: right;
    width: 100%;
    color: var(--text-color-dark);
}

.event {
    margin: .5rem .1rem .1rem .1rem;
    padding: .2rem .5rem;
    border-radius: 3px;
    font-size: 12px;
    color: var(--text-color-light);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.event.free {
    background-color: #008151;
}

.event.busy {
    background-color: #b8442b;
}

.other-month {
    background-color: var(--background-primary-color);
}

.today {
    background-color: rgba(95, 63, 45, 0.5);
}

.empty::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../img/crossDay.png'); /* Your PNG */
    background-color: rgba(58, 58, 58, 0.1);
    background-size: 100% 100%; /* Adjusts the image size */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* No repeat */
    z-index: 0; /* Ensure the image is above other content */
  }


          /* Adjust cell content for very small screens */
          @media screen and (max-width: 700px) {
            .calendar-header-cell {
                padding: .1rem;
            }
            
            .calendar-cell {
                padding: .1rem;           
            }

            .event {
                margin: .1rem;
                padding: .1rem .2rem;
            }

            .calendar-nav button {
                padding: .5rem 1rem;
                margin: 0 .2rem;
            }

            .calendar-title {
                font-size: 1.5rem;
            }

        }

        @media screen and (max-width: 500px) {
            .calendar-nav button {
                padding: .5rem 1rem;
                margin: 0 .1rem;
            }

            .calendar-title {
                font-size: var(--font-size-4);
            }

                }


                @media screen and (max-width: 400px) {
                    .calendar-header {
                        flex-direction: column;
                    }

                    .calendar-title {
                        margin: .4rem;
                    }
                }