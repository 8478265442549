@font-face {
  font-display: swap;
  font-family: outfit;
  src: url("Outfit-Regular.57c12f65.ttf");
}

:root {
  --font-family-default: "Outfit", Arial, Helvetica, sans-serif;
  --font-family-text: "Outfit", Arial, Helvetica, sans-serif;
  --font-size-0: 4rem;
  --font-size-1: 3rem;
  --font-size-2: 2.5rem;
  --font-size-3: 2rem;
  --font-size-4: 1.3rem;
  --font-size-5: 1.1rem;
  --font-size-6: 1rem;
  --font-size-7: .85rem;
  --font-size-8: .5rem;
  --font-weight-vbold: 800;
  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-medium: 500;
  --font-weight-semilight: 400;
  --font-weight-light: 300;
  --font-weight-vlight: 200;
  --shadow-text-outline: -1px -1px 0 var(--secondary-color), 1px -1px 0 var(--secondary-color), -1px 1px 0 var(--secondary-color), 1px 1px 0 #000;
  --shadow-element: 0px 4px 4px 0px #00000040;
  --shadow-element-medium: 0px 6px 6px 6px #00000040;
  --shadow-element-strong: 0px 8px 8px 8px #00000040;
  --primary-color: #ac7d4e;
  --secondary-color: #53443c;
  --tertiary-color: #cd7e2c;
  --background-primary-color: #f4f2f1;
  --background-secondary-color: #fefcfb;
  --text-color-light: #fff;
  --text-color-dark: #251d1d;
  color-scheme: light only;
}

.dark__mode {
  --primary-color: #ac7d4e;
  --secondary-color: #53443c;
  --tertiary-color: #cd7e2c;
  --background-primary-color: #251d1d;
  --background-secondary-color: #2f2727;
  --text-color-light: #fff;
  --text-color-dark: #f4f2f1;

  & .home {
    background-color: #251d1dcc;
  }

  & .contact-form__input::placeholder {
    color: #f4f2f166;
  }

  & .nav__link {
    color: var(--text-color-dark);
  }

  & .popup {
    background-color: #251d1df2;
  }

  & .footer {
    background-color: #53443cb3;
    box-shadow: 0 -5px 32px -12px #555;
  }

  & .service__title h3 {
    color: var(--text-color-dark);
  }

  & .home__name, & .loading__logo {
    content: url("napis-dark.5e89d25e.svg");
  }

  & .gmap_iframe {
    filter: invert(90%);
  }

  & .swiper-pagination-bullet {
    background: #fff;
  }
}

* {
  box-sizing: border-box;
  font-family: var(--font-family-default);
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body, html {
  overflow-x: hidden;
}

body {
  background-attachment: fixed;
  background-color: var(--primary-color);
  color: var(--text-color-dark);
  background-image: image-set("background-max.709b035d.webp" 1x type("image/webp"), "background-max.2293a4db.jpeg" 1x);
  background-position: right 0 top 0;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: min-content;
  transition: background-color 1s, color 1s;
  position: absolute;
  box-shadow: inset 0 0 600px 200px #000c;
}

@media screen and (width <= 1920px) {
  body {
    background-image: image-set("background-fhd.2620c61b.webp" 1x type("image/webp"), "background-fhd.68d4fe76.jpeg" 1x);
  }
}

@media screen and (width <= 1366px) {
  body {
    background-image: image-set("background-hd.4f17bccd.webp" 1x type("image/webp"), "background-hd.09a931f9.jpeg" 1x);
  }
}

@media screen and (width <= 800px) {
  body {
    background-image: image-set("background-mobile.f0fe7fdc.webp" 1x type("image/webp"), "background-mobile.5d1f0b05.jpeg" 1x);
  }
}

@supports not (background-image: image-set(url("") 1x)) {
  body {
    background-image: url("background-max.2293a4db.jpeg");
  }

  @media screen and (width <= 1920px) {
    body {
      background-image: url("background-fhd.68d4fe76.jpeg");
    }
  }

  @media screen and (width <= 1366px) {
    body {
      background-image: url("background-hd.09a931f9.jpeg");
    }
  }

  @media screen and (width <= 800px) {
    body {
      background-image: url("background-mobile.5d1f0b05.jpeg");
    }
  }
}

body, button, input, textarea {
  font-family: var(--font-family-text);
  font-size: var(--font-size-6);
}

h1, h2, h3 {
  color: var(--text-color-light);
  font-family: var(--font-family-default);
  font-weight: var(--font-weight-medium);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

section {
  scroll-margin-top: 170px;
}

.container {
  max-width: 1240px;
  height: min-content;
  margin: 0 auto;
}

.content {
  max-width: 1140px;
  margin: 0 auto;
}

.main {
  background-color: var(--background-primary-color);
  height: min-content;
}

.title {
  border-bottom: 3px solid #0000;
  border-image: linear-gradient(.55turn, var(--primary-color), var(--secondary-color), var(--primary-color));
  font-size: var(--font-size-3);
  border-image-slice: 1;
  padding-bottom: .5rem;
}

.subtitle, .title {
  color: var(--text-color-dark);
  font-family: var(--font-family-default);
  font-weight: var(--font-weight-vbold);
}

.subtitle {
  font-size: var(--font-size-4);
}

.subtitle, .text {
  align-self: center;
}

.text {
  color: var(--text-color-dark);
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-medium);
  text-align: justify;
  text-justify: inter-word;
  line-height: 1.6;
}

.bold, .text {
  font-family: var(--font-family-text);
}

.bold {
  font-weight: var(--font-weight-bold) !important;
}

.btn {
  background-color: var(--secondary-color);
  border: solid;
  border-color: var(--secondary-color);
  color: var(--text-color-light);
  font-family: var(--font-family-default);
  font-size: var(--font-size-6);
  font-weight: var(--font-weight-semilight);
  letter-spacing: .25rem;
  text-transform: uppercase;
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 3rem;
  margin: .5rem;
  transition: all .3s;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.btn.btn__small {
  height: 2.5rem;
  font-size: .75rem;
}

@media (hover: hover) {
  .btn:hover {
    background-color: var(--background-primary-color);
    color: var(--secondary-color);
  }
}

.dark__mode {
  & .btn:hover {
    background-color: var(--background-primary-color);
    color: var(--text-color-light);
  }
}

.link:link, .link:visited {
  color: var(--primary-color);
}

.link:hover {
  color: var(--tertiary-color);
}

.link:active {
  color: var(--secondary-color);
}

.buttons {
  align-self: center;
}

.buttons, .header__container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.invisible, .invisible * {
  opacity: 0;
}

.hidden, .hidden * {
  transition: none;
  display: none !important;
}

.disabled {
  pointer-events: none;
}

.nav {
  background: var(--primary-color);
  border: 3px solid vaR(--secondary-color);
  z-index: 100;
  border-radius: 3rem;
  flex: 1140px;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  width: 100%;
  min-width: 1140px;
  max-width: 1240px;
  height: 3rem;
  margin: 4rem auto;
  transition: all .5s;
  display: flex;
  position: fixed;
  top: 0;
  box-shadow: inset 10px 20px 50px #00000059;
}

.nav__logo {
  border-radius: 50%;
  min-width: 8rem;
  max-width: 10rem;
  min-height: 8rem;
  max-height: 10rem;
  margin: 1rem;
  position: relative;
}

.nav__logo img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
}

.nav__menu {
  justify-content: space-evenly;
  list-style: none;
  display: flex;
}

#nav__menu-right {
  padding-right: 2rem;
}

#nav__number {
  font-weight: var(--font-weight-vbold);
}

.nav__menu-item {
  white-space: nowrap;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  margin: .3rem;
  padding: 1rem;
  display: flex;
}

#nav__menu-left .nav__menu-item {
  width: 8rem;
}

#nav__menu-left :first-child {
  width: auto;
}

.nav__menu-item.active {
  background-color: var(--background-primary-color);
  transition: all .5s;
}

.nav__menu-item.active .nav__link {
  color: var(--text-color-dark);
}

.nav__current {
  color: var(--secondary-color);
  place-self: center;
  height: 1rem;
  padding: 0 .2rem 0 0;
}

.nav__link {
  color: var(--background-primary-color);
  font-family: var(--font-family-default);
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-medium);
  transition: all .3s;
}

@media (hover: hover) {
  .nav__link:hover {
    transition: all .3s;
    color: var(--secondary-color) !important;
  }
}

.dark__mode {
  @media (hover: hover) {
    & .nav__link:hover {
      transition: all .3s;
      color: var(--secondary-color) !important;
    }
  }
}

.nav__menu-item.nav__social {
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  padding: .3rem;
  display: inline-flex;
}

.nav__menu-item.nav__social .nav__link {
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  display: inline-flex;
}

.nav__menu-item.nav__social .nav__link .fa-stack {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: inline-flex;
}

.nav__menu-item.nav__social .nav__link .fa-stack .fa-square {
  width: 100%;
  height: 100%;
}

.nav__menu-item.nav__social .nav__link .fa-stack .fa-facebook-f, .nav__menu-item.nav__social .nav__link .fa-stack .fa-instagram {
  color: var(--primary-color);
  position: relative;
}

.fa-inverse {
  color: var(--primary-color) !important;
}

.home {
  -webkit-backdrop-filter: blur(4px);
  border: 3px solid var(--secondary-color);
  box-shadow: var(--shadow-element);
  background: #f4f2f1cc;
  border-radius: 1rem;
  justify-content: center;
  align-items: stretch;
  width: 1140px;
  height: 65vh;
  max-height: 600px;
  margin-top: 12rem;
  margin-bottom: 5rem;
  scroll-margin-top: 200px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.home__heading {
  font-size: 1px !important;
  position: absolute !important;
  left: -9999px !important;
}

.home__swiper {
  --swiper-navigation-color: var(--tertiary-color);
  --swiper-pagination-color: var(--tertiary-color);
  --swiper-navigation-sides-offset: 100px;
  --swiper-navigation-size: 2rem;
}

.home__slide {
  display: flex;
}

.home__content {
  border-right: 3px solid var(--secondary-color);
  flex-direction: column;
  flex: 1 0 45%;
  place-content: center space-evenly;
  padding: 2rem 4rem;
  display: flex;
}

.home__subtitle {
  border-bottom: 2px solid var(--secondary-color);
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
}

.home__name, .home__subtitle {
  width: 100%;
  height: min-content;
}

.home__subtitle h2 {
  color: var(--secondary-color);
}

.home__text {
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 1rem 0;
}

.home__text, .home__text-item {
  align-items: center;
  display: flex;
}

.home__text-item {
  -webkit-user-select: none;
  user-select: none;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 100%;
  padding: 1.5rem 1rem;
}

.text-arrow {
  color: var(--text-color-dark);
  font-size: var(--font-size-5);
}

.arrow-text, .text-arrow {
  height: 1rem;
  line-height: 1rem;
}

.arrow-text {
  padding: 0 1rem;
  font-size: 1.6rem;
}

.text-arrow-left {
  transform: rotate(45deg);
}

.home__buttons {
  padding: 2rem 0 1rem;
}

#home__btn_more {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--text-color-light);
}

@media (hover: hover) {
  #home__btn_more:hover {
    background-color: var(--background-primary-color);
    color: var(--primary-color);
  }
}

.home__img {
  background-image: image-set("about-max.9d322a29.webp" 1x type("image/webp"), "about-max.a61f021d.jpeg" 1x);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  flex-basis: 55%;
  align-self: stretch;
  overflow: hidden;
}

@media screen and (width <= 1920px) {
  .home__img {
    background-image: image-set("about-fhd.c616a25c.webp" 1x type("image/webp"), "about-fhd.9542e81b.jpeg" 1x);
  }
}

@media screen and (width <= 1366px) {
  .home__img {
    background-image: image-set("about-hd.a42482ea.webp" 1x type("image/webp"), "about-hd.59a4005c.jpeg" 1x);
  }
}

@media screen and (width <= 800px) {
  .home__img {
    background-image: image-set("about-mobile.2b2e9243.webp" 1x type("image/webp"), "about-mobile.77b058eb.jpeg" 1x);
  }
}

@supports not (background-image: image-set(url("") 1x)) {
  .home__img {
    background-image: url("about-max.a61f021d.jpeg");
  }

  @media screen and (width <= 1920px) {
    .home__img {
      background-image: url("about-fhd.9542e81b.jpeg");
    }
  }

  @media screen and (width <= 1366px) {
    .home__img {
      background-image: url("about-hd.59a4005c.jpeg");
    }
  }

  @media screen and (width <= 800px) {
    .home__img {
      background-image: url("about-mobile.77b058eb.jpeg");
    }
  }
}

.about {
  -webkit-user-select: none;
  user-select: none;
  flex-direction: column;
  min-width: 0;
  padding: 2rem 4rem 4rem;
  display: flex;
}

.about__content {
  gap: 2rem;
  height: 100%;
  min-height: 0;
  margin-top: 2rem;
  display: flex;
  overflow: hidden;
}

.about__content .about__short {
  border-right: none;
}

.about__short {
  scrollbar-color: var(--secondary-color) var(--background-secondary-color);
  scrollbar-width: thin;
  flex-direction: column;
  flex-basis: 33%;
  align-items: center;
  gap: 1rem;
  display: flex;
  overflow: visible auto;
}

.about__short-img {
  border-radius: 1rem;
  width: 200px !important;
  height: auto !important;
}

.about__photo {
  flex: 1;
  justify-content: center;
  align-items: center;
  max-width: 50%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.about__text {
  flex: 2;
  max-width: 80%;
}

.about__methods {
  scrollbar-color: var(--secondary-color) var(--background-secondary-color);
  scrollbar-width: thin;
  padding-right: 2rem;
  overflow-y: auto;
}

.about__photo-left {
  border-right: 3px solid var(--secondary-color);
  padding-right: 2rem;
}

.about__photo-right {
  border-left: 3px solid var(--secondary-color);
  padding-left: 2rem;
}

.about__img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.about__timeline {
  overscroll-behavior: contain;
  scrollbar-color: var(--secondary-color) var(--background-secondary-color);
  scrollbar-width: thin;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-height: 100%;
  padding: 0 2rem 0 0;
  display: flex;
  overflow: hidden scroll;

  & ::-webkit-scrollbar {
    width: 8px;
  }

  & ::-webkit-scrollbar-track {
    background: var(--background-secondary-color);
  }

  & ::-webkit-scrollbar-thumb {
    background-color: var(--secondary-color);
    border: 0 solid #fff;
    border-radius: 10px;
  }
}

.about__timeline_item {
  border-bottom: 1px solid var(--secondary-color);
  flex-wrap: nowrap;
  gap: 2rem;
  width: 100%;
  padding: 2rem 0;
  display: flex;
}

.about__timeline_item:first-child {
  padding-top: 0;
}

.about__timeline_item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.timeline_item-left {
  flex-direction: column;
  flex-basis: 25%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.timeline_item-left-img {
  cursor: pointer;
  object-fit: cover;
  width: 150px;
  max-width: 150px;
  max-height: 150px;
  transition: all .3s ease-in-out;
}

.timeline_item-left-text {
  padding: .2rem;
  font-size: .75rem;
}

.timeline_item-right {
  flex-basis: 75%;
}

.timeline_item-right-year {
  color: var(--primary-color);
}

.timeline_item-right-title {
  font-size: var(--font-size-5);
  padding-bottom: .3rem;
}

.about__paragraph {
  text-indent: 2rem;
}

.services {
  height: min-content;
}

.services__wrapper {
  padding: 2rem 0;
  transition: all 1.5s;
  display: flex;
  overflow: visible !important;
}

.swiper.services__swiper {
  height: min-content;
  margin: 0 1rem 1rem;
  transition: all 1.5s;
  overflow: hidden !important;
}

.services__swiper-button {
  color: var(--tertiary-color) !important;
  position: relative !important;
}

.another__container {
  justify-content: center;
  width: 100%;
}

.another__container, .service {
  align-items: center;
  display: flex;
}

.service {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  flex: 0 0 25%;
  min-width: 270px;
  height: 100%;
  padding: 0 .3rem;
  position: relative;
  height: 600px !important;
  transition: all 1.5s !important;
}

.service__content:hover {
  box-shadow: var(--shadow-element-medium);
  transition: all .5s;
}

.service__content {
  background-color: var(--background-secondary-color);
  border: 3px solid var(--secondary-color);
  box-shadow: var(--shadow-element);
  border-radius: 15px;
  overflow: hidden;
}

.service__content, .service__wrapper {
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  display: flex;
  height: 600px !important;
}

.service__title {
  background-color: var(--background-primary-color-color);
  border-bottom: 5px solid var(--primary-color);
  border-top: 5px solid var(--primary-color);
  justify-content: center;
  align-items: center;
  min-height: 4rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
}

.service__title h3 {
  color: var(--secondary-color);
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-vbold);
  text-align: center;
  text-transform: uppercase;
}

.service__picture {
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
  display: flex;
}

.service__picture-img {
  border-radius: 6px;
  height: 10rem;
}

.service__list {
  flex-direction: column;
  justify-content: space-evenly;
  align-self: stretch;
  align-items: flex-start;
  margin: auto;
  padding: .5rem;
  display: flex;
}

.service .service__list-item {
  justify-content: center;
  align-items: center;
  padding: .8rem;
  display: flex;
}

.service .service__list-icon {
  height: 1.5rem;
  padding: 0 .2rem;
}

.service .service__list-desc {
  color: var(--text-color-dark);
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-semibold);
  padding: 0 .5rem;
}

.service__more {
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  place-content: center;
  width: 100%;
  padding: 1rem;
  display: flex;
}

.service__more-btn {
  width: 7rem;
  height: 2.5rem;
}

.service__expanded {
  flex: 1 0 100%;
}

.service__expanded-loaded {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.service__expanded__headline {
  border-bottom: 3px solid var(--secondary-color);
  align-self: stretch;
  align-items: center;
  gap: 2rem;
  max-height: 250px;
  margin: 2rem 2rem 0;
  padding: 0 0 2rem;
  display: flex;
}

.service__expanded__headline-picture {
  border-right: 3px solid var(--secondary-color);
  flex: 2;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100%;
  display: flex;
}

.service__expanded__headline-picture-img {
  object-fit: contain;
  max-width: 100%;
  height: 100%;
}

.service__expanded__headline-description {
  border-right: 3px solid var(--secondary-color);
  flex-direction: column;
  flex: 2;
  align-items: center;
  width: min-content;
  height: 100%;
  padding-right: 2rem;
  display: flex;
}

.service__expanded__headline-price {
  border: 2px solid var(--secondary-color);
  border-radius: 1rem;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 200px;
  min-width: 200px;
  display: flex;
}

.price-mobile {
  display: none;
}

.service__expanded__price-title {
  justify-content: center;
  align-items: center;
  display: flex;
}

.service .service__expanded-price-icon {
  color: var(--primary-color);
}

.service__expanded__list {
  flex: 100%;
  margin-top: 2rem;
  padding: 1rem 2rem;
}

.service__expanded-price {
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-semilight);
  text-align: center;
  margin: .5rem;
}

.service__expanded__title {
  font-size: var(--font-size-5);
  text-transform: capitalize;
  z-index: 10;
  font-weight: 1000;
}

.service__expanded__subheadline {
  font-size: var(--font-size-1);
  justify-content: center;
  align-items: center;
  margin-top: .8rem;
  display: flex;
}

.service__expanded__text {
  font-size: var(--font-size-6);
  font-weight: var(--font-weight-medium);
}

.service__expanded__list {
  flex-direction: column;
  flex: 2;
  justify-content: center;
  align-items: flex-end;
  margin-top: 1rem;
  display: flex;
}

.service__expanded__list-item {
  align-items: center;
  width: 100%;
  padding: .5rem 0;
  display: flex;
}

.service__expanded__list-icon {
  height: 1.2rem;
  margin-right: .8rem;
}

.service__expanded__list-desc {
  color: var(--text-color-dark);
  font-size: var(--font-size-6);
}

.service__expanded_buttons {
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
  display: flex;
}

.news__content {
  gap: 2rem;
  padding: 2rem 0;
  display: flex;
}

.news__card {
  background-color: var(--background-secondary-color);
  border: 3px solid var(--secondary-color);
  box-shadow: var(--shadow-element);
  cursor: pointer;
  flex: 2.5;
  flex-basis: 33, 3%;
  overscroll-behavior: contain;
  border-radius: .5rem;
  flex-flow: column;
  flex-shrink: 0;
  width: auto;
  min-height: 27rem;
  max-height: 27rem;
  margin: 1rem 0;
  transition: all .5s;
  display: flex;
  overflow: hidden;
}

.news__card * {
  -webkit-user-select: none;
  user-select: none;
}

.news__card.active * {
  -webkit-user-select: auto;
  user-select: auto;
}

.news__card * {
  transition: all .5s;
}

@media (hover: hover) {
  .news__card:hover {
    box-shadow: var(--shadow-element-strong);
    transition: all .5s;
    transform: scale(1.02);
  }
}

.news__card.active {
  flex: 5;
  min-height: 25rem;
  transition: all 1s;
}

@media screen and (width >= 1140px) {
  .news__card.inactive .news__card-cover {
    height: 175px;
  }
}

.news__card-cover {
  height: 200px;
}

.news__card-cover.news__card-cover-hidden, .news__card.active .news__card-cover {
  height: 0;
}

.news__card-img {
  object-fit: cover;
  object-position: center;
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem;
  width: 100%;
  height: 100%;
}

.news__card-data {
  border-top: 3px solid var(--secondary-color);
  overscroll-behavior: contain;
  flex-direction: column;
  flex: 5;
  padding: 1rem 1rem 0;
  display: flex;
  overflow: hidden;
}

.news__card.active .news__card-data {
  overscroll-behavior: contain;
  scrollbar-color: var(--secondary-color) var(--background-secondary-color);
  scrollbar-width: thin;
  border: none;
  flex: 6;
  justify-content: flex-start;
  overflow-y: auto;
}

.news__card-date {
  color: var(--primary-color);
  font-size: var(--font-size-5);
  width: 100%;
  margin-bottom: .5rem;
}

.news__card-title {
  font-size: var(--font-size-4);
  text-transform: capitalize;
  width: 100%;
  margin-bottom: 1rem;
}

.news__card-description {
  font-size: var(--font-size-6);
  overflow-wrap: break-word;
  text-indent: 2rem;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 5rem;
  max-height: 5rem;
  padding: 0;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.news__card.active .news__card-description {
  flex: 0;
  min-height: auto;
  max-height: min-content;
  display: block;
}

.text__overflowed {
  background: var(--background-secondary-color);
  font-size: var(--font-size-5);
  height: 1.6rem;
  transition: all .2s;
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
}

.news__card.active .news__card-description {
  height: auto;
  overflow: visible;
}

.news__card.active .news__card-read-more, .news__card.active .text__overflowed {
  display: none;
}

.news__card-read-more {
  color: var(--primary-color);
  font-size: var(--font-size-5);
  text-align: center;
  align-self: flex-end;
  width: 100%;
  height: min-content;
  margin-top: auto;
  padding: .6rem 0;
  display: block;
}

.news__card-read-more span {
  color: inherit;
  font-size: inherit;
  transition: all .2s;
}

@media (hover: hover) {
  .news__card:hover .news__card-arrow {
    margin: 0 5px;
  }
}

.news__card-buttons {
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: .5rem;
  transition: all .5s;
  display: none;
}

.news__card.active .news__card-buttons {
  display: flex;
}

.news__card-btn {
  font-size: var(--font-size-6);
  width: min-content;
  height: 2.5rem;
  padding: 0 1rem;
}

.news__card-content {
  display: none;
}

.news__card.active .news__card-content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.news__card.active .news__card-description {
  line-height: 1.8;
}

.news__card-list {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding: .5rem 0;
  display: flex;
}

.news__card-list-item {
  justify-content: center;
  align-items: center;
  padding: .5rem;
  display: flex;
}

.news__card-list-icon {
  height: 1rem;
}

.news__card-list-desc {
  color: var(--text-color-dark);
  font-size: var(--font-size-6);
  font-weight: var(--font-weight-medium);
  padding: 0 .5rem;
}

.news__card-paragraph {
  font-size: var(--font-size-6);
  text-indent: 2rem;
  width: 100%;
  line-height: 1.8;
}

.news__card-distinct-text {
  font-size: var(--font-size-5);
  text-align: center;
  width: 100%;
  padding: .5rem 0;
  line-height: 1.8;
}

.gallery {
  height: min-content;
}

.gallery__swiper {
  width: 100%;
  margin: 3rem;
  display: flex;
}

.gallery__swiper, .swiper-cards {
  overflow: hidden !important;
}

.gallery__card {
  border-radius: .7rem;
}

.gallery__img {
  width: 100%;
  height: 100%;
}

.gallery__swiper-pagination {
  color: var(--text-color-light);
}

.custom-shape-divider-bottom-1693308080 {
  width: 100%;
  margin-bottom: -3px;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1693308080 svg {
  width: calc(100% + 1.3px);
  height: 112px;
  display: block;
  position: relative;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1693308080 .shape-fill {
  fill: var(--background-primary-color);
}

::-webkit-scrollbar {
  background-color: var(--background-secondary-color);
  width: .7rem;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: .2rem;
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  30% {
    transform: scale(1.3);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.pulsing {
  animation: .3s pulse;
}

.pulsing, .pulsing * {
  transition: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadingIn {
  animation-name: fadeIn;
  animation-duration: 1s;
}

.fadingIn, .fadingIn * {
  transition: none;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadingOut {
  animation-name: fadeOut;
  animation-duration: 1s;
}

.fadingOut, .fadingOut * {
  transition: none;
}

@keyframes fadeOutFadeIn {
  0% {
    opacity: 1;
  }

  30% {
    opacity: 0;
  }

  90% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes scaleUp {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  70% {
    opacity: 1;
    transform: scale(1.2);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.scalingUp {
  animation-name: scaleUp;
  animation-duration: .5s;
}

.scalingUp, .scalingUp * {
  transition: none;
}

@keyframes scaleDown {
  0% {
    transform: scale(1);
  }

  30% {
    opacity: 1;
    transform: scale(1.2);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}

.scalingDown {
  animation-name: scaleDown;
  animation-duration: .5s;
}

.scalingDown, .scalingDown * {
  transition: none;
}

@keyframes scaleUpAbs {
  0% {
    opacity: 0;
    transform: scale(0)translate(-50%, -50%);
  }

  70% {
    opacity: 1;
    transform: scale(1.2)translate(-50%, -50%);
  }

  to {
    opacity: 1;
    transform: scale(1)translate(-50%, -50%);
  }
}

.scalingUpAbs {
  animation-name: scaleUpAbs;
  animation-duration: .5s;
}

.scalingUpAbs, .scalingUpAbs * {
  transition: none;
}

@keyframes scaleDownAbs {
  0% {
    opacity: 1;
    transform: scale(1)translate(-50%, -50%);
  }

  30% {
    opacity: 1;
    transform: scale(1.2)translate(-50%, -50%);
  }

  to {
    opacity: 0;
    transform: scale(0)translate(-50%, -50%);
  }
}

.scalingDownAbs {
  transition: none;
  animation-name: scaleDownAbs;
  animation-duration: .5s;
}

.scalingDownAbs * {
  transition: none;
}

@keyframes expand {
  0% {
    flex: 1;
  }

  to {
    flex: 1 0 100%;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(20%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.item-shown {
  animation-name: slideIn;
  animation-duration: .5s;
}

.item-shown, .item-shown * {
  transition: none;
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(20%);
  }
}

.item-hidden {
  animation-name: slideOut;
  animation-duration: .5s;
}

.item-hidden, .item-hidden * {
  transition: none;
}

.contact__content {
  flex-wrap: wrap;
  gap: 3rem;
  margin-top: 1rem;
  padding: 2rem 0;
  display: flex;
}

.contact__info-container {
  flex-direction: column;
  flex: 1 0 0;
  display: flex;
}

.contact__form-container {
  flex-direction: column;
  flex-basis: 65%;
  display: flex;
}

.contact__map-container {
  border-radius: .5rem;
  flex-basis: 100%;
  display: flex;
}

.contact__data {
  flex-direction: column;
  gap: 2rem;
  display: flex;
}

.contact__information {
  align-items: center;
  display: flex;
}

.contact__text {
  font-weight: var(--font-weight-medium);
}

.contact__information-icon {
  color: var(--primary-color);
  font-size: var(--font-size-2);
  width: 2rem;
}

.contact__information-box {
  margin-left: 2rem;
}

.contact__map {
  border: 2px solid var(--secondary-color);
  border-radius: .5rem;
  flex-basis: 100%;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  min-height: 400px;
  display: flex;
  overflow: hidden;
}

.contact__map-img {
  object-fit: cover;
  width: 100%;
  max-height: 400px;
}

.contact-form {
  flex-wrap: wrap;
  flex-basis: 60%;
  gap: .8rem;
  display: flex;
}

.contact-form__field {
  width: 100%;
  padding-bottom: 10px;
  position: relative;
}

.contact-form__label {
  font-family: var(--font-family-default);
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-semibold);
  margin-bottom: 1rem;
}

.contact-form__input {
  border: 2px solid var(--secondary-color);
  color: var(--text-color-dark);
  background-color: #0000;
  border-radius: .5rem;
  outline: none;
  width: 100%;
  height: 4rem;
  padding: 1rem 1rem 0;
  transition: all .5s;
}

.contact-form__input:focus {
  background-color: var(--background-secondary-color);
  box-shadow: var(--shadow-element);
}

.contact-form__input:focus .contact-form__label {
  color: green;
}

#email, #name {
  flex: 1 1 0;
}

.contact-form__input--textarea {
  height: 10rem;
  padding-top: 1.8rem;
}

.contact-form__label {
  background-color: var(--background-primary-color);
  border-bottom: 2px solid var(--secondary-color);
  border-left: 2px solid var(--secondary-color);
  border-right: 2px solid var(--secondary-color);
  color: var(--text-color-dark);
  border-radius: .4rem;
  padding: 0 .3rem;
  position: absolute;
  top: 0;
  left: 10px;
  font-weight: 400 !important;
}

.contact-form__input::placeholder {
  color: #0006;
}

.popup {
  width: 85vw;
  max-width: 85vw;
  height: 85vh;
  max-height: 85vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image__popup {
  z-index: 110;
  display: none;
}

.image__popup img {
  border: 3px solid var(--secondary-color);
  object-fit: contain;
  background-color: #e0e1e0f2;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 1rem;
  display: block;
  max-width: 85vw !important;
  max-height: 85vh !important;
}

.loading {
  contain: paint;
  z-index: 200;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  inset: 0;
}

.loading, body .blurry__background {
  transition: all 1s;
}

.loading__logo {
  max-width: 70vw;
  max-height: 15vh;
  display: block;
}

.loading__img {
  width: auto;
  max-width: 80vw;
  height: auto;
  max-height: 40vh;
}

.loading__text {
  color: var(--text-color-light);
  font-size: var(--font-size-4);
  text-align: center;
  text-shadow: var(--shadow-text-outline);
  padding: 0 2rem;
}

.blurry__background {
  backdrop-filter: blur(5px);
  z-index: 109;
  width: 100%;
  height: 100%;
  transition: all 1.5s;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.blurry__background.blurry__background-strong {
  backdrop-filter: blur(35px);
  background-color: var(--background-primary-color);
  opacity: .95;
  z-index: 199;
  display: block;
}

.btn__close-popup {
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  color: var(--background-primary-color);
  cursor: pointer;
  font-size: var(--font-size-3);
  z-index: 100;
  border-radius: 50%;
  margin-top: -13px;
  margin-right: -13px;
  position: absolute;
  top: 0;
  right: 0;
}

.dark__mode {
  & .btn__close-popup {
    background-color: var(--text-color-light);
  }

  & .btn__close-popup:hover {
    background-color: var(--secondary-color);
  }
}

@media (hover: hover) {
  .btn__close-popup:hover {
    color: var(--primary-color);
  }
}

.footer {
  border-top: 3px solid var(--primary-color);
  background-color: #53443cb3;
  box-shadow: 0 -5px 32px -12px #42445a;
}

.footer__container {
  justify-content: space-between;
  padding: 2rem 2rem 4rem;
  display: flex;
}

.footer__subtitle {
  color: var(--text-color-light);
  font-size: var(--font-size-4);
  margin-bottom: 1.5rem;
}

.footer__list {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.footer__link {
  color: var(--text-color-light);
  font-size: var(--font-size-6);
  align-items: center;
  gap: .6rem;
  transition: all .3s;
  display: flex;
}

@media (hover: hover) {
  .footer__link:hover {
    color: var(--primary-color);
  }
}

.footer__link-icon {
  color: var(--primary-color);
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  display: flex;
}

.service__expanded .service__wrapper {
  overscroll-behavior: contain;
  scrollbar-color: var(--secondary-color) var(--background-secondary-color);
  scrollbar-width: thin;
  overflow: hidden auto;
}

@media screen and (width <= 1240px) {
  .nav {
    width: auto;
    min-width: min-content;
  }

  .calendar__title, .contact__content, .contact__title, .gallery__title, .news__content, .news__title, .services__title {
    margin: 0 2rem;
  }
}

@media (height <= 500px), screen and (width <= 1140px) {
  #nav__menu-left {
    flex-direction: column;
    height: auto;
  }

  .nav__toggle {
    cursor: pointer;
    display: flex;
  }

  .contact__content {
    padding: 2rem;
  }

  .swiper-button-next.services__swiper-button-next {
    right: 8px;
  }

  .swiper.services__swiper {
    margin: 0 1rem;
  }
}

@media screen and (width <= 1240px) {
  .swiper-button-prev.services__swiper-button-prev {
    left: 8px;
  }

  .swiper-button-next.services__swiper-button-next, .swiper-button-prev.services__swiper-button-prev {
    --swiper-navigation-size: 22px;
  }
}

@media (width <= 1400px) and (height <= 650px), screen and (width <= 1140px) {
  .about {
    min-height: 0;
  }

  .about__content {
    overscroll-behavior: contain;
    scrollbar-color: var(--secondary-color) var(--background-secondary-color);
    scrollbar-width: thin;
    flex-direction: column;
    height: 100%;
    padding: 0 2rem;
    overflow-y: auto;
  }

  .about__short {
    border-bottom: 3px solid var(--secondary-color);
    max-height: min-content;
    padding: 0 0 2rem;
    overflow-y: visible;
  }

  .about__short-text {
    text-indent: 2rem;
    height: min-content;
    min-height: auto;
  }

  .about__timeline {
    scrollbar-color: none;
    scrollbar-width: none;
    flex: 0 0 100%;
    padding: 0;
    overflow: visible;
  }

  .about__photo {
    flex: none;
    order: 2;
    width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 0;
  }

  .about__img {
    max-width: 95%;
    max-height: 100%;
  }

  .about__photo-left {
    border-right: none;
    padding-right: 0;
  }

  .about__photo-right {
    border-left: none;
    padding-left: 0;
  }

  .about__text {
    padding-right: 0;
  }

  .about__methods {
    width: 100%;
    overflow: visible;
  }

  .about__content {
    padding: 0 2rem 0 0;
  }

  .about__text {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (width <= 1140px) {
  .nav {
    width: 570px;
  }

  .nav-toggled {
    height: 21rem;
  }

  .home {
    margin-left: auto;
  }

  .home__slide {
    flex-direction: column;
  }

  .home {
    max-width: 570px;
  }

  .home__img {
    display: none;
  }

  .news__content {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  .news__card {
    max-width: 500px;
  }

  .news__card-data {
    height: 15.5rem;
    max-height: 15.5rem;
  }

  .news__card.active .news__card-data {
    height: min-content;
    max-height: none;
  }

  .calendar__content {
    margin: 0 2rem;
  }

  .contact__content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 2rem;
  }

  .contact__form-container, .contact__map-container {
    width: 100%;
  }

  .contact__data {
    flex-flow: wrap;
  }

  .contact__information {
    width: 47%;
  }

  .service {
    flex: 0 0 33.33%;
  }

  .news__card.active .news__card-data {
    overflow: visible;
  }

  .service__expanded-loaded {
    flex-direction: column;
  }

  .service__expanded {
    flex: 0 0 100%;
  }

  .service__expanded__headline-picture {
    padding-right: 2rem;
  }

  .service__expanded .service__content {
    padding: 0;
    overflow: hidden;
  }

  .nav__toggle {
    cursor: pointer;
    font-size: var(--font-size-4);
    justify-content: center;
    align-items: center;
    width: 3rem;
    margin-left: 2rem;
    padding: .5rem;
    display: flex;
  }
}

@media screen and (width <= 800px) and (height <= 700px) {
  .service__list-desc {
    font-size: var(--font-size-4);
  }

  .service__list-icon {
    height: 1.5rem;
  }

  .service__list-item {
    padding: .7rem;
  }
}

@media screen and (width <= 800px) and (height <= 850px) {
  .service__picture-img {
    height: 15rem;
  }
}

@media screen and (width <= 700px) {
  .contact__data {
    flex-direction: column;
  }

  .contact__information {
    width: 100%;
  }
}

@media screen and (width <= 400px) {
  .service .service__expanded__headline-description {
    padding: 0;
  }

  .service__expanded__list-icon {
    height: 1rem;
  }

  .service__expanded__list {
    padding: 1rem;
  }

  .contact__information-box {
    margin-left: 1rem;
  }

  .contact__information-box .subtitle {
    font-size: var(--font-size-5);
  }

  .contact__information-icon {
    font-size: var(--font-size-3);
  }
}

@media screen and (width <= 800px) {
  .service {
    flex: 0 0 50%;
  }

  .service__expanded {
    flex: 0 0 100%;
  }

  .service__expanded-price {
    font-size: var(--font-size-4);
    align-self: center;
    width: 100%;
  }
}

@media screen and (width <= 900px) {
  .service__expanded__headline {
    border-bottom: none;
    flex-flow: wrap;
    flex-shrink: 0;
    justify-content: center;
    gap: 1rem;
    height: min-content;
    max-height: min-content;
  }

  .service .service__expanded__title {
    font-size: var(--font-size-5);
    margin: 0 .5rem .5rem;
  }

  .service__expanded__subheadline {
    width: 100%;
  }

  .service__expanded__headline-picture {
    border-right: none;
    flex: 0;
    align-self: flex-start;
    width: 45%;
    min-width: 200px;
    height: min-content;
    max-height: 200px;
    padding: 0 1rem;
  }

  .service__expanded__list {
    justify-content: flex-start;
  }

  .service__expanded__headline-picture-img {
    height: 100%;
  }

  .service__expanded__headline-description {
    border-top: none;
    border-right: none;
    flex-direction: column;
    flex: 50%;
    order: 2;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: min-content;
    margin: 1rem 0 .5rem;
    padding: 0;
  }

  .service__expanded__title {
    font-size: var(--font-size-4);
    text-align: center;
    width: 100%;
    margin: 1rem 0;
  }

  .service__expanded__headline {
    padding: 0;
  }

  .service__expanded__list {
    margin-top: 0;
  }

  .timeline_item-right {
    flex-basis: 100%;
  }

  .arrow-text {
    padding: 0 1.5rem;
  }
}

@media screen and (width <= 800px) and (height <= 650px) {
  .service__expanded__headline-description {
    order: 0;
  }
}

@media screen and (width <= 600px) {
  .service__expanded__headline {
    margin-top: 1rem;
  }
}

@media screen and (width >= 600px) and (width <= 800px) and (height <= 650px) {
  .service__expanded__text {
    max-width: 80%;
  }
}

@media screen and (width <= 500px) {
  .swiper .home__content {
    padding: 1rem;
  }

  .content {
    margin: 0;
  }
}

@media screen and (height <= 600px) and (width <= 600px) {
  .home__content {
    padding: 2rem;
  }

  .about {
    padding: 1rem 2rem 2rem;
  }

  .swiper-button-next.home__swiper-button-next, .swiper-button-prev.home__swiper-button-prev {
    top: 96%;
  }

  .swiper.home__swiper {
    --swiper-navigation-size: 1rem;
    padding-bottom: .5rem;
  }

  .home__content {
    border-right: none;
  }
}

@media screen and (width <= 600px) and (height <= 600px) {
  .home .home__text-item {
    padding: .5rem 1rem;
  }
}

@media (height <= 650px), screen and (width <= 600px) {
  .home {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .home__buttons {
    padding-bottom: 0;
  }

  .btn.home__btn {
    padding: 0 1rem;
  }

  .swiper-button-next.home__swiper-button-next {
    z-index: 99;
    width: 2rem;
    height: 2rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    top: 96%;
  }

  .swiper-button-prev.home__swiper-button-prev {
    width: 2rem;
    height: 2rem;
    padding-bottom: .5rem;
    padding-right: 1rem;
    top: 96%;
  }

  .swiper-pagination.home__swiper-pagination {
    transform: translate(-50%);
    width: 50% !important;
    bottom: 5px !important;
    left: 50% !important;
  }

  .swiper.home__swiper {
    --swiper-navigation-size: 1rem;
  }

  .swiper.gallery__swiper {
    padding: 0 5rem;
  }

  .about {
    padding: 1rem 1rem 2rem;
  }

  .about__content {
    padding: 0 1rem;
  }
}

@media screen and (width <= 600px) {
  .nav {
    width: auto;
    min-width: 280px;
    padding: 1rem;
  }

  #nav__menu-right {
    display: none;
  }

  #nav-toggle {
    margin-left: 0;
    padding: 1rem;
  }

  .nav-toggled {
    flex-direction: column;
    height: 25rem;
  }

  .nav-toggled .nav__logo {
    display: none;
  }

  .arrow-text {
    text-align: left;
    line-height: 1.5rem;
  }

  .arrow-text, .home__text {
    height: min-content;
  }

  .home__text-item {
    padding: .8rem 1rem;
  }

  .home__buttons {
    width: 50%;
  }

  .home__content {
    flex-basis: 0%;
    min-width: 0;
    padding: 2rem;
  }

  .about__timeline_item {
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }

  .service__expanded__headline-picture {
    order: 0;
    width: 100%;
    min-width: 200px;
  }

  .gallery__swiper {
    margin: 2rem;
  }

  .contact__content {
    padding: 2rem;
  }

  .news__card {
    max-height: 500px;
  }

  .news__card.active {
    max-height: min-content;
  }

  .footer__container {
    flex-direction: column;
    gap: 2rem;
  }

  .service {
    flex: 0 0 100%;
  }

  .contact__information {
    width: 100%;
  }

  .news__card-buttons {
    flex-wrap: wrap;
  }
}

@media screen and (width <= 600px) and (width >= 500px) {
  .news__card {
    width: 100%;
  }
}

@media screen and (width <= 800px) and (height >= 500px) {
  .arrow-text {
    height: auto;
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
}

@media screen and (width <= 460px) {
  .arrow-text {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media screen and (width <= 500px) {
  .home__name {
    width: 100%;
  }

  .news__card {
    margin: 1rem 2rem;
  }
}

@media screen and (width <= 400px) {
  .home__text-item {
    padding: .8rem 0;
  }

  .about__short {
    flex: 100%;
    height: min-content;
    overflow: visible;
  }

  .timeline_item-right-text {
    text-justify: auto;
  }

  .news__card {
    min-width: 0;
  }

  .news__card-list-item {
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .news__card-read-more {
    font-size: var(--font-size-6);
  }

  .news__card-data {
    padding: 1rem 1rem 0;
  }

  .news__card-description {
    min-width: 0;
  }

  .swiper.gallery__swiper {
    padding: 0 2.5rem;
  }

  .btn.home__btn {
    font-size: var(--font-size-7);
    width: 7rem;
  }
}

@media screen and (height <= 927px) {
  .home__subtitle {
    flex: 0 auto;
    width: auto;
    min-width: 0;
    height: auto;
    min-height: 70px;
    display: flex;
    overflow: hidden;
  }

  .home__name {
    object-fit: contain;
    align-self: center;
    height: 100%;
  }

  .home__img {
    flex: 75%;
  }

  .home__text {
    height: min-content;
  }

  .home {
    max-height: 70vh;
    margin-top: 11rem;
  }
}

.service .service__list-desc {
  font-size: var(--font-size-5);
}

.service .service__list-item {
  padding: .5rem;
}

.service .service__list-icon {
  height: 1rem;
}

@media screen and (height >= 850px) and (width <= 950px) {
  .service .service__list-desc {
    font-size: var(--font-size-6);
  }

  .service .service__list-item {
    padding: .5rem;
  }

  .service .service__list-icon {
    height: .9rem;
  }
}

@media screen and (height >= 850px) and (width <= 600px) {
  .service .service__list-desc {
    font-size: var(--font-size-5);
  }

  .service .service__list-item {
    padding: .5rem 0;
  }

  .service .service__list-icon {
    height: 1.2rem;
  }
}

@media screen and (height <= 850px) and (width >= 800px) {
  .service__picture {
    display: none;
  }
}

@media screen and (height <= 850px) and (width <= 800px) {
  .service .service__list-desc {
    font-size: var(--font-size-4);
  }

  .service .service__list-item {
    padding: .7rem;
  }

  .service .service__list-icon {
    height: 1.3rem;
  }
}

@media screen and (height <= 650px) {
  .service .service__list-desc {
    font-size: .9rem;
  }

  .service .service__list-item {
    padding: .3rem;
  }

  .service .service__list-icon {
    height: .9rem;
  }

  .service .service__more {
    padding: .3rem;
  }
}

@media screen and (height <= 650px) and (width <= 800px) {
  .service .service__list-desc {
    font-size: var(--font-size-4);
  }

  .service .service__list-item {
    padding: .5rem;
  }

  .service .service__list-icon {
    height: 1.3rem;
  }

  .service .service__more {
    padding: .5rem;
  }
}

@media screen and (height <= 500px) {
  .service .service__list-desc {
    font-size: var(--font-size-4);
  }

  .service .service__list-item {
    padding: .5rem;
  }

  .service .service__list-icon {
    height: 1.3rem;
  }
}

@media (height <= 500px), screen and (height <= 850px) and (width <= 800px) {
  :root {
    font-size: 12px;
  }

  .service__title h3 {
    font-size: var(--font-size-4);
  }
}

@media screen and (height <= 850px) and (width >= 800px) {
  .service__expanded .service__wrapper {
    flex-direction: row;
  }

  .service__expanded .service__content {
    padding-bottom: 1rem;
  }

  .service__expanded__headline {
    border-bottom: none;
    flex-flow: wrap;
    flex: 1;
    height: min-content;
    max-height: 100%;
    margin: 2rem 1rem;
    padding: 0;
  }

  .service__expanded__headline-picture-img {
    max-width: 80%;
  }

  .service.service__expanded * {
    font-size: 16px;
  }

  .service .service__expanded__btn {
    font-size: var(--font-size-7);
    padding: 1rem 0;
  }

  .service__expanded__headline-picture {
    border-right: none;
    width: 12rem;
  }

  .service__expanded__headline-price {
    margin: 1rem 1.5rem;
    position: absolute;
    top: 0;
    right: 0;
  }

  .service__expanded__headline-description {
    border-right: none;
    width: auto;
    padding-right: 0;
  }

  .service__expanded_buttons {
    width: min-content;
    margin: 1rem;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .service__expanded__list {
    justify-content: flex-start;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 220px;
    padding: 1rem 1rem 0;
  }

  .service__expanded__headline-description {
    margin: 0 .5rem;
  }

  .service .service__expanded__title {
    margin: 0;
  }

  .nav {
    margin: 3rem auto;
  }

  .home {
    height: 75vh;
    max-height: 75vh;
    margin-top: 10rem;
  }

  .about__short-text {
    min-height: auto;
  }

  .service, .service__content {
    height: 500px !important;
  }

  .service__list-desc {
    font-size: var(--font-size-5);
  }
}

@media screen and (height <= 650px) {
  .service__expanded__headline-picture, .service__picture {
    display: none;
  }

  .service, .service__content, .services__wrapper {
    height: 300px !important;
  }

  .service.service__expanded * {
    font-size: 12px;
  }
}

@media screen and (height <= 850px) {
  .service__expanded__headline-picture {
    min-width: 200px;
    max-height: 200px;
    padding: 1rem 0;
  }
}

@media screen and (height <= 850px) and (width >= 800px) {
  .service__expanded_buttons {
    flex-direction: column;
  }
}

@media screen and (height <= 500px) and (width <= 1140px) {
  .home__buttons {
    display: none;
  }
}

@media screen and (height <= 500px) and (width >= 1140px) {
  .home {
    width: auto;
    margin-left: 10rem;
    margin-right: 10rem;
  }
}

@media screen and (width <= 800px) {
  .home {
    min-height: 300px;
  }

  .service__expanded__list-item {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .home__text-item {
    padding: 1rem;
  }
}

@media screen and (height <= 750px) and (width <= 1140px) {
  .home__buttons {
    display: none;
  }
}

@media (width <= 800px), screen and (height <= 700px) {
  .service * {
    font-size: 12px;
  }

  .service__title {
    margin-top: 1.5rem;
  }

  .home__buttons {
    display: none;
  }

  .service__more-btn.btn__small {
    width: min-content;
    height: min-content;
    padding: .3rem .5rem;
  }

  .service__expanded__btn.btn__small {
    width: 8rem;
    height: 2rem;
    padding: .5rem;
  }
}

@media screen and (height <= 400px) and (width <= 1140px) {
  .home__subtitle .home__name {
    width: 70%;
  }
}

@media screen and (height <= 360px) and (width <= 1140px) {
  .home__text .home__text-item {
    padding: .8rem;
  }
}

@media screen and (height <= 650px) and (width >= 500px) {
  .home__name {
    width: 45%;
  }

  .home__text-item {
    padding: .8rem;
  }
}

@media screen and (height <= 500px) and (width >= 600px) {
  .news__content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (width <= 560px) {
  .price-normal {
    display: none;
  }

  .price-mobile {
    align-self: center;
    margin: 1rem 0;
    display: flex;
  }
}

@media (width >= 600px) and (width <= 1140px), screen and (height <= 500px) and (width >= 550px) {
  .news__card {
    flex-direction: row;
    min-width: 500px;
    max-width: 60%;
    height: 16rem;
    min-height: 16rem;
    max-height: 16rem;
  }

  .news__content {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  .news__card-data {
    border-top: none;
  }

  .news__card-cover {
    border-right: 3px solid var(--secondary-color);
    width: 50%;
    height: auto;
  }

  .news__card.active {
    flex-direction: column;
    height: 70vh;
    max-height: 70vh;
  }

  .news__card.active .news__card-data {
    width: 100%;
    overflow-y: auto;
  }
}

@media screen and (height >= 651px) and (width >= 1141px) {
  .nav__toggle {
    display: none;
  }
}

@media screen and (height <= 650px) and (width >= 800px) {
  .home {
    height: calc(100vh - 2rem);
    max-height: none;
    margin-top: 1rem;
  }

  .home__name {
    width: 100%;
  }

  .home__subtitle {
    padding-bottom: 2rem;
  }

  .home__content {
    justify-content: center;
    padding-top: 1rem;
  }

  .text-arrow {
    font-size: var(--font-size-4);
  }

  .home__text-item {
    padding: 1.5rem 1rem;
  }

  .nav__toggle {
    margin: 1rem;
  }

  .nav.nav-toggled {
    width: 9rem;
    overflow: hidden;
  }

  .nav-toggled .nav__logo {
    display: none;
  }

  .nav, .nav__menu {
    flex-direction: column;
  }

  .nav {
    justify-content: center;
    align-items: center;
    width: 4rem;
    min-width: auto;
    height: 90%;
    margin: 0;
    padding: 1rem 0;
    top: 50%;
    left: 2rem;
    transform: translate(0, -50%);
  }
}

@media screen and (height >= 650px) and (width <= 1140px) {
  .home__subtitle {
    padding-bottom: 2rem;
  }

  .home__content {
    justify-content: center;
    padding-top: 1rem;
  }

  .text-arrow {
    font-size: var(--font-size-4);
  }

  .arrow-text {
    font-size: 1.75rem;
  }
}

@media (width >= 800px) and (height <= 650px), screen and (width <= 600px) {
  #nav__menu-right {
    display: none;
  }

  #nav__toggle {
    align-self: center;
    padding-left: 0;
  }
}

@media screen and (width >= 1140px) and (width <= 1240px) and (height >= 650px) {
  #nav__menu-right {
    display: none;
  }
}

@media screen and (width <= 1400px) and (height <= 650px) {
  .home__img {
    display: none;
  }

  .home {
    max-width: 570px;
  }
}

@media screen and (width >= 2500px) {
  * {
    font-size: 32px;
  }

  .nav {
    flex: 72rem;
    width: 100%;
    min-width: 72rem;
    max-width: 78rem;
  }

  .content {
    max-width: 72rem;
    margin: 0 auto;
  }

  .home {
    width: 72rem;
    max-height: 38rem;
    margin-top: 11rem !important;
    margin-bottom: 3rem !important;
    scroll-margin-top: 11rem !important;
  }

  .about__short-img {
    width: 13rem !important;
  }

  .timeline_item-left-img {
    width: 10rem;
    max-width: 10rem;
    max-height: 10rem;
  }

  .news__card-cover {
    height: 13rem;
  }

  .service__expanded__headline {
    max-height: 16rem;
  }

  .service__expanded__headline-price {
    width: 13rem;
    min-width: 13rem;
  }

  .gallery__swiper {
    margin-top: 2rem;
  }

  .swiper-pagination-bullet {
    width: .5rem !important;
    height: .5rem !important;
  }

  .service__expanded__headline-picture {
    width: 10rem;
  }

  .container {
    max-width: 78rem;
    height: min-content;
    margin: 0 auto;
  }

  .service, .service__content, .service__wrapper {
    height: 38rem !important;
  }
}

.dark__mode-toggle {
  background-color: var(--primary-color);
  border: 3px solid var(--secondary-color);
  cursor: pointer;
  z-index: 99;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  transition: all .5s;
  display: flex;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.dark__mode-toggle:hover {
  & .dark__mode-icon {
    color: var(--secondary-color);
  }
}

.dark__mode-icon {
  color: var(--background-primary-color);
  font-size: var(--font-size-4);
}

.fc-day {
  max-height: 30px !important;
}

.fc-toolbar-title {
  color: var(--text-color-dark);
}

.fc-day {
  color: var(--text-color-dark) !important;
}

.fc-col-header-cell {
  color: var(--text-color-light) !important;
}

.calendar__content {
  padding: 2rem 0;
}

@media screen and (width <= 1240px) {
  .calendar__content {
    margin: 0 2rem;
  }
}

@media screen and (width <= 1140px) {
  .contact__content {
    margin: 0;
  }
}

:root {
  --fc-small-font-size: .85em;
  --fc-page-bg-color: var(--background-primary-color);
  --fc-neutral-bg-color: #d1d1d14d;
  --fc-neutral-text-color: grey;
  --fc-border-color: #53443c59;
  --fc-button-text-color: var(--text-color-light);
  --fc-button-bg-color: var(--secondary-color);
  --fc-button-border-color: var(--text-color-dark);
  --fc-button-hover-bg-color: var(--background-primary-color);
  --fc-button-hover-border-color: var(--text-color-dark);
  --fc-button-active-bg-color: var(--background-primary-color);
  --fc-button-active-border-color: var(--text-color-dark);
  --fc-bg-event-opacity: .3;
  --fc-highlight-color: #bce8f14d;
  --fc-today-bg-color: #53443c80;
}

.fc .fc-button-primary:hover {
  color: var(--secondary-color) !important;
}

.fc .fc-col-header-cell {
  background-color: var(--secondary-color);
  color: var(--text-color-light);
}

.fc .fc-day-other {
  position: relative;
}

.fc .fc-day-other:before {
  content: "";
  z-index: 0;
  background-image: url("crossDay.0f9f27cb.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.fc .fc-day {
  transition: none;
}

.dark__mode {
  & .fc .fc-day-other {
    background-color: #e6e6e626;
  }

  & .fc .fc-day-other:before {
    background-image: url("crossNight.d3168268.png");
  }

  & .fc .fc-day-today {
    background-color: #cd7e2c4d !important;
  }
}

@media screen and (width <= 400px) {
  .fc .fc-button, .fc .fc-toolbar-title {
    font-size: 1.3rem !important;
  }
}

@media screen and (width <= 400px) and (height >= 850px) {
  .fc .fc-button, .fc .fc-toolbar-title {
    font-size: .9rem !important;
  }
}

.calendar__info {
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
  display: flex;
}

.calendar__disclaimer {
  align-items: center;
  max-width: 70%;
  display: flex;
}

@media screen and (width <= 800px) {
  .calendar__disclaimer {
    max-width: 100%;
  }
}

@media screen and (width <= 500px) {
  .calendar__disclaimer {
    max-width: 100%;
  }
}

.calendar__info-icon, .contact__info-icon, .services__info-icon {
  color: var(--primary-color);
  font-size: var(--font-size-3);
  margin-right: 2rem;
}

.legend__green, .legend__red {
  flex-wrap: nowrap;
  align-items: center;
  display: flex;
}

.legend__red .legend__square {
  background-color: #b8442b;
  width: 2rem;
  height: 1rem;
  margin-right: 1rem;
}

.legend__green .legend__square {
  background-color: #008151;
  width: 2rem;
  height: 1rem;
  margin-right: 1rem;
}

.contact__info, .services__info {
  align-items: center;
  display: flex;
}

.services__info {
  flex-wrap: nowrap;
  margin: 2rem 3rem 0;
}

@media screen and (width <= 1140px) {
  .services__info {
    margin: 2rem 2rem 0;
  }
}

.calendar-container {
  box-sizing: border-box;
  width: 100%;
  max-width: 1140px;
  margin: 1rem auto;
}

.calendar-header {
  background-color: var(--background-primary-color);
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  display: flex;
}

.calendar-title {
  color: var(--text-color-dark);
  font-size: var(--font-size-3);
}

.calendar-nav button {
  background-color: var(--secondary-color);
  color: var(--text-color-light);
  cursor: pointer;
  font-size: var(--font-size-4);
  border: none;
  margin: 0 .5rem;
  padding: .5rem 1rem;
}

.calendar-grid {
  border: 1px solid #53443c59;
  grid-template-columns: repeat(7, 1fr);
  display: grid;
}

.calendar-header-cell {
  background-color: var(--secondary-color);
  color: var(--text-color-light);
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-bold);
  text-align: center;
  border: 1px solid #53443c59;
  padding: 10px;
}

.calendar-cell {
  border: 1px solid #53443c59;
  min-width: 0;
  min-height: 100px;
  padding: .3rem;
  position: relative;
}

.date-number {
  color: var(--text-color-dark);
  text-align: right;
  width: 100%;
}

.event {
  color: var(--text-color-light);
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 3px;
  margin: .5rem .1rem .1rem;
  padding: .2rem .5rem;
  font-size: 12px;
  overflow: hidden;
}

.event.free {
  background-color: #008151;
}

.event.busy {
  background-color: #b8442b;
}

.other-month {
  background-color: var(--background-primary-color);
}

.today {
  background-color: #5f3f2d80;
}

.empty:before {
  content: "";
  z-index: 0;
  background-color: #3a3a3a1a;
  background-image: url("crossDay.0f9f27cb.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (width <= 700px) {
  .calendar-cell, .calendar-header-cell {
    padding: .1rem;
  }

  .event {
    margin: .1rem;
    padding: .1rem .2rem;
  }

  .calendar-nav button {
    margin: 0 .2rem;
    padding: .5rem 1rem;
  }

  .calendar-title {
    font-size: 1.5rem;
  }
}

@media screen and (width <= 500px) {
  .calendar-nav button {
    margin: 0 .1rem;
    padding: .5rem 1rem;
  }

  .calendar-title {
    font-size: var(--font-size-4);
  }
}

@media screen and (width <= 400px) {
  .calendar-header {
    flex-direction: column;
  }

  .calendar-title {
    margin: .4rem;
  }
}
/*# sourceMappingURL=index.8614434b.css.map */
